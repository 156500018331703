import React, { useEffect, useState } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import ProtectedRoute from "./util/_ProtectedRoute";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import Pages from "./pages/Pages";
import AppointmentPage from "./pages/AppointmentPage";
import DoctorPage from "./pages/DoctorPage";
import SecondOpinionPage from "./pages/SecondOpinion";
import ExpertsPage from "./pages/ExpertsPage";
import BlogPage from "./pages/BlogPage";
import ContactPage from "./pages/ContactPage";
import RootPage from "./components/Root/Root";
import Error from "./pages/Error";
import BlogDetailPage from "./pages/BlogDetailPage";
import FAQPage from "./pages/FAQPage";
import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import PartnersPage from "./pages/PartnersPage";

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
    const checkUserToken = () => {
        const userToken = localStorage.getItem('user-token');
        if (!userToken || userToken === 'undefined') {
            setIsLoggedIn(false);
        }
        setIsLoggedIn(true);
    }
    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootPage />}>
        <Route index element={<HomePage />} />
        <Route path="/aboutus" element={<AboutPage />} />
        <Route path="/pages" element={<Pages />} />
        <Route path="/appointment" element={<ProtectedRoute><AppointmentPage /></ProtectedRoute>} />
        <Route path="/getopinion" element={<SecondOpinionPage />} />
        <Route path="/expert" element={<ExpertsPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/blog/:blogSlug" element={<BlogDetailPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/getdoctor" element={<DoctorPage />} />
        <Route path="/partner" element={<PartnersPage />} />
        {/* <Route path="*" element={<Navigate to="/" />} /> */}
        <Route path="*" element={<Error />} />
      </Route>
    ),
    { basename: "/" }
  );

  return <RouterProvider router={router} />;
}

export default App;
