import React, { Fragment } from "react";
import ExpertDoctorDesign from "../ExpertDoctorDesign/ExpertDoctorDesign";

// images
import SeniorConsultantOrthopedician from "../../../assets/20.jpg";
import Consultant from "../../../assets/21.jpg";
import DirectorGynaecologistIVFSpecialist from "../../../assets/22.jpg";

// css
import { Col, Container, Row } from "react-bootstrap";
import classes from "./ExpertDoctor.module.css";

const ExpertDoctor = () => {
  const Expert_Doctor_Design = [
    {
      id: "d1",
      name: "Dr. Avinash Pal",
      edu: "MBBS,MS Orthopedics",
      doctorSpecialties: "Senior Consultant Orthopedician",
      consultationDay: "Monday to Saturday",
      consultationTime: "09:30 AM To 05:00 PM",
      doctorImage: SeniorConsultantOrthopedician,
      doctorImageAlt: "Senior Consultant Orthopedician",
      orderClassOne: "order-1",
      orderClassTwo: "order-2",
    },
    {
      id: "d2",
      name: "Dr. Bharat Singh Rawat",
      edu: "MBBS, DNB (Pulmonary Medicine), IDCCM, DAA",
      doctorSpecialties: "Consultant",
      consultationDay: "Monday to Saturday",
      consultationTime: "09:30 AM To 05:00 PM",
      doctorImage: Consultant,
      doctorImageAlt: "Consultant",
      orderClassOne: "order-1",
      orderClassTwo: "order-2",
    },
    {
      id: "d3",
      name: "Dr. Anju Chhabra",
      edu: "MBBS,MS (Obs & Gynae)",
      doctorSpecialties: "Director & Gynaecologist & IVF Specialist",
      consultationDay: "Monday to Saturday",
      consultationTime: "09:30 AM To 05:00 PM",
      doctorImage: DirectorGynaecologistIVFSpecialist,
      doctorImageAlt: "Director & Gynaecologist & IVF Specialist",
      orderClassOne: "order-1",
      orderClassTwo: "order-2",
    },
    {
      id: "d4",
      name: "Dr. Avinash Pal",
      edu: "MBBS,MS Orthopedics",
      doctorSpecialties: "Senior Consultant Orthopedician",
      consultationDay: "Monday to Saturday",
      consultationTime: "09:30 AM To 05:00 PM",
      doctorImage: SeniorConsultantOrthopedician,
      doctorImageAlt: "Senior Consultant Orthopedician",
      orderClassOne: "order-1",
      orderClassTwo: "order-2",
    },
    {
      id: "d5",
      name: "Dr. Anju Chhabra",
      edu: "MBBS,MS (Obs & Gynae)",
      doctorSpecialties: "Director & Gynaecologist & IVF Specialist",
      consultationDay: "Monday to Saturday",
      consultationTime: "09:30 AM To 05:00 PM",
      doctorImage: DirectorGynaecologistIVFSpecialist,
      doctorImageAlt: "Director & Gynaecologist & IVF Specialist",
      orderClassOne: "order-1",
      orderClassTwo: "order-2",
    },
    {
      id: "d6",
      name: "Dr. Bharat Singh Rawat",
      edu: "MBBS, DNB (Pulmonary Medicine), IDCCM, DAA",
      doctorSpecialties: "Consultant",
      consultationDay: "Monday to Saturday",
      consultationTime: "09:30 AM To 05:00 PM",
      doctorImage: Consultant,
      doctorImageAlt: "Consultant",
      orderClassOne: "order-1",
      orderClassTwo: "order-2",
    },
  ];

  return (
    <Fragment>
      <section className={classes["expert-doctor-section"]}>
        <Container fluid="lg">
          <Row>
            <Col xs="12">
              <div className={classes["expert-team"]}>
                <h3>Coming Soon!</h3>
                {/* <h3>Meet Our Experts</h3> */}
              </div>
            </Col>
          </Row>
          {/* <ExpertDoctorDesign export_doctor_design={Expert_Doctor_Design} /> */}
        </Container>
      </section>
    </Fragment>
  );
};

export default ExpertDoctor;
