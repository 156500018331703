import React, { Fragment, useEffect, useState } from "react";
import HeaderMenuLink from "../HeaderMenuLink/HeaderMenuLink";
import { Link, useNavigate } from "react-router-dom";

// icons
import { FaBars, FaTimes } from "react-icons/fa";

// css
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from 'react-bootstrap/Dropdown';
import classes from "./OpinionNav.module.css";
import Button from "../Ui/Button/Button";

//logo
import logo from "../../../assets/EAO_logo.png";

const OpinionNav = () => {
  const navigate = useNavigate();
  const [scrollPage, setScrollPage] = useState(false);
  const [show, setShow] = useState(false);

  const scrollPageHandler = () => {
    if (window.scrollY > 300) {
      setScrollPage(true);
    } else {
      setScrollPage(false);
    }
  };
  window.addEventListener("scroll", scrollPageHandler);

  // showNavbarHandler click
  const showNavbarHandler = () => {
    setShow(true);
  };

  // useEffect using add a class in body
  useEffect(()=>{
    document.body.classList.toggle('show-nav', show)
  },[show])

  //closeNavbarHandler click
  const closeNavbarHandler = () => {
    setShow(false);
  };

  const loginChangeHandler = () => {
    setTimeout(() => {
      window.location.replace('https://admin.ekauropinion.com/');
      // window.location.replace('http://localhost:8000/login');
      
  }, 500);
  }

  const registerChangeHandler = () => {
    setTimeout(() => {
      navigate('/getdoctor');
  }, 500);
  }
  
  return (
    <Fragment>
      <Navbar
        expand="lg"
        className={`${scrollPage ? classes.scrollPageFixed : ""} ${
          show ? classes["show-nav"] : ""
        } ${classes["header-nav"]}`}
      >
        <Container fluid="lg">
          <Link to="/" className={classes['navbar__brand']}>
            <img src={logo} alt="EkAurOpinion" width="144"></img>
          </Link>
          <Button
            type="button"
            onClick={showNavbarHandler}
            className={classes["collapse-navbar"]}
          >
            <FaBars />
          </Button>
          <Navbar.Collapse className={`${show ? classes.showNav : ""} justify-content-end`}>
            <div className={classes["box-nav"]}>
              <Button
                type="button"
                onClick={closeNavbarHandler}
                className={classes["close-navbar"]}
              >
                <FaTimes />
              </Button>
              <HeaderMenuLink scrollPageSend={scrollPage}
                onClick={closeNavbarHandler} />
            </div>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic" className={classes['doctor_login']}>
                Join as Doctor
              </Dropdown.Toggle>

              <Dropdown.Menu className={classes['doctor_login-menu']}>
                <Dropdown.Item >
                  <button
                      type="button"
                      className={classes["link-button"]}
                      onClick={loginChangeHandler}
                    >Login</button>
                </Dropdown.Item>
                <Dropdown.Item >
                  <button
                      type="button"
                      className={classes["link-button"]}
                      onClick={registerChangeHandler}
                    >Register as Doctor</button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/* <div className={classes['doctor_login-box']}>
              <Link to="/getdoctor" className={classes['doctor_login']}>Join as Doctor</Link>
            </div> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Fragment>
  );
};

export default OpinionNav;
