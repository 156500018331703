import React, { Fragment } from "react";
import Banner from "../components/SameLayout/Banner/Banner";
import Appointment from "../components/AppointmentPage/Appointment/Appointment";

// images
import InnerBanner from "../assets/16.jpg";

const AppointmentPage = () => {
  return (
    <Fragment>
      <Banner
        bannerBackgroundImage={InnerBanner}
        pageTitle="Second Opinion"
        parentPageTo="/"
        parentPageName="Home"
      />
      <Appointment />
    </Fragment>
  );
};

export default AppointmentPage;
