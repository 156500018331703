import React, { Fragment } from "react";
import { Link } from "react-router-dom";

// css
import { Container } from "react-bootstrap";
import classes from './HeaderStrap.module.css'


const HeaderStrap = (props) => {
  const {pageTitle,parentPageName,parentPageTo}=props;

  return (
    <Fragment>
      <section className={classes["inner-banner"]} >
        <Container>
          <ul className={classes["thm-breadcrumb"]}>
            <li>
              <Link to={parentPageTo}>{parentPageName}</Link>
            </li>
            <li>
              <span className={classes.sep}>/</span>
            </li>
            <li>
              <span className={classes["page-title"]}>{pageTitle}</span>
            </li>
          </ul>
        </Container>
      </section>
    </Fragment>
  );
};

export default HeaderStrap;
