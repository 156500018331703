import React, { Fragment, useEffect } from "react";
import ReactDOM from "react-dom";
import Button from "../Button/Button";

// css
import classes from "./Model.module.css";

const Overlay = (props) => {
  return <div className={classes["backdrop"]} onClick={props.onClose}></div>;
};

const ModelContainer = (props) => {
  return (
    <Fragment>
      <div className={classes["model"]}>
        <div className={classes["model-title"]}>
          <h5>{props.children.title}</h5>
          <div className={classes["model-body"]}>
            {props.children.text}
            {props.children.textTwo}
            {props.children.textThree}
            {props.children.textFour}
            {props.children.textFive}
            {props.children.textSix}
            {props.children.textSeven}
          </div>
        </div>
        <div className={classes["model-btn"]}>
          <Button className={classes["onclose-btn"]} onClick={props.onClose}>
            Close
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

const Model = (props) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, []);

  const overlayModel = document.getElementById("overlay-model");
  return ReactDOM.createPortal(
    <Fragment>
      <Overlay onClose={props.onClose} />
      <ModelContainer onClose={props.onClose}>{props.children}</ModelContainer>
    </Fragment>,
    overlayModel
  );
};

export default Model;
