import React, { Fragment, useEffect, useRef, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import classes from "./AboutUs.module.css";
import Button from "../../SameLayout/Ui/Button/Button";

// import images
import AboutUsImg from "../../../assets/About-Us.jpg";
import DoctorOne from "../../../assets/2.jpg";
import Patience from "../../../assets/3.jpg";
import DocGroup from "../../../assets/4.jpg";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  const aboutNavigate = useNavigate();
  const sectionRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isAboutPage, setIsAboutPage] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if(!sectionRef.current) return
      const section = sectionRef.current;
      const sectionTop = section.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      // Calculate the scroll position to trigger the animation
      const scrollPosition = sectionTop - windowHeight + 200; // Adjust the value to control the animation

      setIsScrolled(window.pageYOffset > scrollPosition);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const aboutUsClickHandler = (event) => {
    event.preventDefault();
    setIsAboutPage(true);
  };

  useEffect(() => {
    if (isAboutPage) {
      setIsAboutPage(false);
      aboutNavigate("/aboutus");
      window.scrollTo(0, 0);
    }
  }, [isAboutPage, aboutNavigate]);

  return (
    <Fragment>
      <section
        className={`${classes["about-one"]} ${
          isScrolled ? classes.scrolled : ""
        }`}
        ref={sectionRef}
      >
        <Container fluid="lg">
          <Row>
            <Col xs="12" lg="6" className={classes.leftSide}>
              <div className={classes["about-one__content-block"]}>
                <h3 className={classes["about-one__title"]}>Who We Are</h3>
                <p className={classes["about-one__tag-line"]}>
                  Our expertise and strength
                </p>
                <p className={classes["about-one__text"]}>
                With ekauropinion, you can consult with medical experts directly for second opinion consultations, making us India's only medical health brand like no other! Our mission at Ekauropinion is to disrupt the second opinion market in India through collaboration with leading medical experts from around the world to provide our patients and patrons with world-class second opinion expertise.
                </p>
                <p className={classes["about-one__text"]}>
                In addition to our clinical specialties, we have partnered with the industry's best names in Oncology, Cardiology, and Neurology.
                </p>
                <Button
                  onClick={aboutUsClickHandler}
                  className={`${classes["about-one__btn"]}`}
                  type="button"
                >
                  Learn More
                </Button>
              </div>
            </Col>
            <Col
              xs="12"
              lg="6"
              className={`${classes["image-column"]} ${classes.rightSide}`}
            >
              <div
                className={`${classes["about-one__image-wrap"]} ${classes.wow} ${classes.slideInRight}`}
                data-wow-duration="1s"
              >
                <div className={classes["about-one__image"]}>
                      <Image
                        fluid
                        src={AboutUsImg}
                        alt="Awesome Doctor Images"
                      />
                </div>
                {/* <Row className={classes["no-gutters"]}>
                  <Col xs="12" sm="7" className={classes.colSevenHeight}>
                    <div className={classes["about-one__image"]}>
                      <Image
                        fluid
                        src={DoctorOne}
                        alt="Awesome Doctor Images"
                      />
                    </div>
                  </Col>
                  <Col xs="12" sm="5" className={classes.colFiveHeight}>
                    <div className={classes["about-one__image"]}>
                      <Image fluid src={Patience} alt="Doctor with Patience" />
                    </div>
                    <div className={classes["about-one__image"]}>
                      <Image
                        fluid
                        src={DocGroup}
                        alt="Awesome Doctor Another Images"
                      />
                    </div>
                  </Col>
                </Row> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default AboutUs;
