import React, { Fragment } from "react";
import CountUp from 'react-countup';

// import css
import classes from './SecondOpinion.module.css'
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SecondOpinionData } from "./SecondOpinionData";
import Button from "../../SameLayout/Ui/Button/Button";

const SecondOpinion = () => {
  return (
    <Fragment>
      <section className={classes["about-three"]}>
        <Container fluid="lg">
          <div className={`${classes["block-title"]}`}>
            <h2 className={classes["block-title__title"]}>Medical Diagnoses Statistic</h2>
            {/* <p className={classes["block-title__text"]}>
             */}
          </div>
          <Row>
            <Col xs="12" md="6" lg="6">
               <div className={`${classes["about-three__content"]}`} >
                <p className={classes["about-three__tag-line"]}>
                  Medical Diagnoses and Their Impact
                </p>
                <p className={`${classes["about-three__text"]}`}>
                According to a Mayo Clinic study conducted in 2017, 88% of patients seeking a second opinion will return with an updated diagnosis or a revised diagnosis after seeking a second opinion. In contrast, 21% of patients will receive a "distinctly different" diagnosis as a result. Conversely, the study was published in 2017 in the Journal of Evaluation in Clinical Practice and found that 12% of patients learned the original diagnosis was correct.
                </p>
              </div>
            </Col>
            <Col xs="12" md="6" lg="6" className={`d-flex`}>
              <div className={`${classes["my-auto"]}`}>
              <Row>
                {
                SecondOpinionData.map(data=>{
                  return(
                    <Col xs="12" md="6" lg="6" key={data.id}>
                      <div
                        className={`${classes["about-three__box"]}`}
                      >
                        <div className={classes["about-three__box-icon"]}>
                          {/* <p className={classes["about-three__box-text"]}> */}
                          <CountUp start={0} end={data.iconNumber} duration={2.75} ></CountUp>{data.iconSymbol}
                          {/* </p> */}
                        </div>
                        <p className={classes["about-three__box-title"]}>
                          {data.iconLinkText}
                        </p>
                      </div>
                    </Col>
                    )
                  })
                }
              </Row>
              </div>
            </Col>

          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default SecondOpinion;
