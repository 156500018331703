import { faqs } from "./faqItems";
import React, { Fragment } from "react";
import Accordion from 'react-bootstrap/Accordion';
import classes from "./faq.module.css";

const FAQ = () => {
    return (
        <Fragment>
            <h3 className={classes["faq-heading"]}>Frequently Asked Question</h3>
            <Accordion className={classes["accordion"]} defaultActiveKey="0">
                {faqs.map((faq, index) => (
                <>
                    <Accordion.Item className={classes["faq-accordion-item"]}eventKey={index}>
                        <Accordion.Header className={classes["accordion-question"]}>Q. {faq.question}</Accordion.Header>
                        <Accordion.Body className={classes["accordion-body"]}>
                        A. {faq.answer}
                        </Accordion.Body>
                    </Accordion.Item>
                    
                </>
                ))}
            </Accordion>
        </Fragment>
    );
  };

export default FAQ;