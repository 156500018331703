import React, { Fragment } from "react";
import AppointmentForm from "./AppointmentForm";

// css
import classes from "./Appointment.module.css";
import { Container } from "react-bootstrap";

const Appointment = () => {
  return (
    <Fragment>
      <section className={`${classes["appointment-one"]}`}>
        <Container>
          <div
            className={`${classes["inner-container"]} ${classes.fadeInUp} ${classes.wow}`}
            data-wow-duration="1s"
          >
            <h3 className={classes["appointment-one__title"]}>
              Get an Appointment
            </h3>
            <AppointmentForm />
          </div>
        </Container>
      </section>
    </Fragment>
  );
};

export default Appointment;
