export const faqs = [
    {
        // https://www.stanfordchildrens.org/en/landing/second-opinion/faq
      question: "When should I get an online second opinion?",
      answer:
        "If the patient or patient family is faced with complex medical issues or difficult treatment decisions, second opinions are a great way to gather expertise input into their care plan. Access our specialists from our highly ranked Academic Medical Center. Please note, a second opinion is not advised during Medical Emergencies or for immediate treatment guidance needs.",
    },
    {
      question: "What should I do once I have the second opinion?",
      answer:
        "The specialist will send over a very thorough second opinion report addressing the specific questions asked during the second opinion request. The results of the report may agree with your original diagnosis or may offer additional treatment options. You should also plan to initially schedule time with your local treating provider to review the recommendations and work with him or her to refine your diagnosis, treatment plan, or plan of care. You are welcome to seek care at our clinic for your treatment after reviewing your second opinion report.",
    },
    // https://www.secondopinions.com/resource-center/faq-patients.html
    {
        question: "Why should I use ekauropinion.com service?",
        answer:
          "As a patient, you have an important say in who provides your health care and where you receive it. Our mission at ekauropinion.com is to maximize the quality of patient care by providing you with important resources to make the most informed decisions. ekauropinion.com gives you the information and confidence you need to help you and your physician make the best treatment decisions, without the delays, expense and inconvenience traditionally associated with such requests.",
      },
    {
      question: "How can I get a medical second opinion from ekauropinion.com?",
      answer:
        "The process is very simple. You need to register, verify your email and complete a appointment form by describing the health issue via audio or video recording/uploading.",
    },
    // https://www.asksecondopinion.com/
    {
      question: "Is my document safe in the ekauropinion.com?",
      answer:
        "Yes. Your medical documents are only shared with doctors. We have a strong privacy policy with proper encryption.",
    },
    {
      question: "Is Online healthcare consultation Helpful? ",
      answer:
        "Yes, online healthcare consultation is helpful for instant medical attention at an emergency situation.",
    },
    
  ];