import React, { Fragment, useState, useEffect } from "react";

import { IoIosArrowUp } from "react-icons/io";

// css
import classes from "./BottomToTop.module.css";

const BottomToTop = () => {
  const [backToTopButton, setBackToTopButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setBackToTopButton(true);
      } else {
        setBackToTopButton(false);
      }
    });
  });

  const backToTopHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Fragment>
      {backToTopButton && (
        <button className={classes.bottomToTopBtn} onClick={backToTopHandler}>
          <IoIosArrowUp />
        </button>
      )}
    </Fragment>
  );
};

export default BottomToTop;
