// import images
import SlideOne from "../../../assets/testi-2-1.jpg";
import SlideTwo from "../../../assets/testi-2-3.jpg";
import SlideThree from "../../../assets/testi-2-2.jpg";
import SlideFour from "../../../assets/testi-2-1.jpg";
import SlideFive from "../../../assets/testi-2-3.jpg";

export const TestimonialCarouselItem=[
    {
        id: "c1",
        carouselImage: SlideOne,
        Title: "Dr. Anju Chhabra",
        designation: "Dental Care",
        paragraphText: "After taking ekauropinion, it provided me a peace of mind and increased my confidence in taking treatment decisions.",
        imageAlt: "Slider One"
    },
    {
        id: "c2",
        carouselImage: SlideTwo,
        Title: "Dr. Bharat Singh Rawat",
        designation: "Consultant",
        paragraphText: "After taking ekauropinion, it provided me a peace of mind and increased my confidence in taking treatment decisions.",
        imageAlt: "Slider One"
    },
    {
        id: "c3",
        carouselImage: SlideThree,
        Title: "Anjum Rai",
        designation: "Student",
        paragraphText: "After taking ekauropinion, it provided me a peace of mind and increased my confidence in taking treatment decisions.",
        imageAlt: "Slider One"
    },
    {
        id: "c4",
        carouselImage: SlideFour,
        Title: "Dr. Anju Chhabra",
        designation: "Dental Care",
        paragraphText: "After taking ekauropinion, it provided me a peace of mind and increased my confidence in taking treatment decisions.",
        imageAlt: "Slider One"
    },
    {
        id: "c5",
        carouselImage: SlideFive,
        Title: "Dr. Bharat Singh Rawat",
        designation: "Health Care",
        paragraphText: "After taking ekauropinion, it provided me a peace of mind and increased my confidence in taking treatment decisions.",
        imageAlt: "Slider One"
    },
]
