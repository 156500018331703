import React, { Fragment } from "react";

// images
import AwesomeImage from "../../../assets/About-Second-Opinion-new.png";

// css
import { Col, Container, Image, Row } from "react-bootstrap";
import classes from "./AboutSecondOpinion.module.css";

const AboutSecondOpinion = () => {
  return (
    <Fragment>
      <section
        className={`${classes["about-two"]}`}
      >
        <Container fluid="lg">
          <Row>
            <Col xs="12" lg="6">
              <div
                className={`${classes["about-four__image-block"]}`}
              >
                <Image fluid src={AwesomeImage} alt="Awesome Images" />
              </div>
            </Col>
            <Col xs="12" lg="6" className={`d-flex`}>
              <div className={`${classes["about-two__content"]} my-auto`}>
                <h3 className={classes["about-two__title"]}>
                  About ekauropinion
                </h3>
                <p className={`${classes["about-two__text"]}`}>
                Ekauropinion is India’s first and only medical health brand that enables second opinion consultations with medical experts here! At Second Opinion we are disrupting the second opinion market in India by collaborating with leading medical experts from across the globe in order to bring world class second opinion expertise to Indian patients and patrons.
                </p>
                <p className={`${classes["about-two__text"]}`}>
                We have clinical specialties including Oncology, Cardiology and Neurology and have partnered with some of the leading names in the industry.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default AboutSecondOpinion;
