import React, { Fragment, useState, useRef } from "react";
import Button from "../../SameLayout/Ui/Button/Button";
import Checkbox from "../../SameLayout/Ui/Checkbox/checkbox";
import { Link, useNavigate } from "react-router-dom";
import Model from "../../SameLayout/Ui/Modal/Model";
import LoadingSpinner from "../../SameLayout/Loader/LoadingSpinner";
import { Baseurls } from "../../SameLayout/BaseUrl/Baseurls";
import Swal from 'sweetalert2';

// icons
import {
  FaUserAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaFileSignature,
  FaIdCard,
  FaClinicMedical,
  FaMedal,
  FaUserNurse,
} from "react-icons/fa";
import {
  AiFillCalendar,
  AiFillHome,
  AiTwotoneCrown,
} from "react-icons/ai";

// css
import classes from "./DoctorDetail.module.css";
import { Col, Row, Image } from "react-bootstrap";
import Form from "react-bootstrap/Form";


const DoctorDetail = () => {

  // navigate
  const navigate = useNavigate();

  //Loader-spinner
  const [isLoading, setIsLoading] = useState(false);

  // select gender
  const [male, setMale] = useState(true);
  const [female, setFemale] = useState(false);

  // multi files upload medical certificate
  const [UploadedProfileImage, setUploadedProfileImage] = useState([]);
  const profileRef = useRef(null);

  // multi files upload medical certificate
  const [UploadedCV, setUploadedCV] = useState([]);
  const cvRef = useRef(null);
  
  // multi files upload medical certificate
  const [uploadedMedicalFiles, setUploadedMedicalFiles] = useState([]);
  const file1InputRef = useRef(null);

  // multi files upload MS Md Certificate
  const [uploadedMDFiles, setUploadedMDFiles] = useState([]);
  const file2InputRef = useRef(null);

  // for declaration
  const [isChecked, setIsChecked] = useState(false);

  // modal state
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  // for local stored values
  const [email, setEmail] = useState(window.localStorage.getItem("email"));
  
  const [appointmentInput, setAppointmentInput] = useState({
    enterName: "",
    enterEmail: "",
    enterDob: "", 
    enterNumber: "",
    enterAddress: "",
    enterClinicAddress: "",
    enterDoctor: "",
    enterQualification: "",
    enterDesignation: "",
    enterRegNumber: "",
    gender: "male",
    medicalCertificate: [],
    msmdCertificate: [],
    profileImage: [],
    enterCV: [],
  });

  const nameRef = React.createRef();
  const emailRef = React.createRef();
  const phoneRef = React.createRef();
  const ageRef = React.createRef();
  const addressRef = React.createRef();
  const clinicRef = React.createRef();
  const doctorRef = React.createRef();
  const qualificationRef = React.createRef();
  const designationRef = React.createRef();
  const regNumberRef = React.createRef();
  const declarationRef = React.createRef();

  const [error, setError] = useState({
    nameError: "",
    emailError: "",
    ageError: "",
    numberError: "",
    profileImageError: "",
    cvError: "",
    medicalCertificateError: "",
    msmdCertificateError: "",
    addressError: "",
    clinicAddressError: "",
    doctorError: "",
    qualificationError: "",
    designationError: "",
    regNumberError: "",
    declaractionError: " ",
  });

  const Select_Doctor = [
    "Cardiology Specialist",
    "Eyes care Specialist",
    "Pediatrics Specialist",
    "Hepatology Specialist",
  ];

  const nameChangeHandler = (event) => {
    const selectName = event.target.value;
    let onlyLetter = /^[a-zA-Z\s]*$/;
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      enterName: selectName,
    }));
    if (selectName.trim().length === 0) {
      setError((prevError) => ({
        ...prevError,
        nameError: "Please enter your name!",
      }));
    } else if (selectName.trim().length < 3) {
      setError((prevError) => ({
        ...prevError,
        nameError: "Please enter minimum 3 letters name!",
      }));
    } else if (!onlyLetter.test(selectName)) {
      setError((prevError) => ({
        ...prevError,
        nameError: "Please enter only letters!",
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        nameError: "",
      }));
    }
  };

  const emailChangeHandler = (event) => {
    const selectEmail = event.target.value;
    let emailValidation = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    // const user = window.localStorage.getItem("email");  //local storage email
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      enterEmail: selectEmail,
    }));
    if (selectEmail.trim().length === 0) {
      setError((prevError) => ({
        ...prevError,
        emailError: "Please enter your email address!",
      }));
    } else if (!emailValidation.test(selectEmail)) {
      setError((prevError) => ({
        ...prevError,
        emailError: "Please enter valid email address!",
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        emailError: "",
      }));
    }
  };

  const ageChangeHandler = (event) => {
    const selectAge = event.target.value;
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      enterDob: selectAge,
    }));
    if (selectAge.trim().length === 0) {
      setError((prevError) => ({
        ...prevError,
        ageError: "Please enter your age!",
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        ageError: "",
      }));
    }
  };

  // select gender
  const maleClickHandler = () => {
    setMale(true);
    setFemale(false);
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      gender: "male",
    }));
  };

  const femaleClickHandler = () => {
    setMale(false);
    setFemale(true);
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      gender: "female",
    }));
  };

  const numberChangeHandler = (event) => {
    const selectNumber = event.target.value;
    let onlyNumber = /^[0-9\b]+$/;
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      enterNumber: selectNumber,
    }));
    if (selectNumber.trim().length === 0) {
      setError((prevError) => ({
        ...prevError,
        numberError: "Please enter your mobile number!",
      }));
    } else if (!onlyNumber.test(selectNumber)) {
      setError((prevError) => ({
        ...prevError,
        numberError: "Please enter only number!",
      }));
    } else if (
      selectNumber.trim().length < 10 ||
      selectNumber.trim().length > 10
    ) {
      setError((prevError) => ({
        ...prevError,
        numberError: "Please enter exactly 10 digits for the mobile number!",
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        numberError: "",
      }));
    }
  };

  // for profile image upload event
  const profileImageChangedHandler = (event) => {
    const profile_image = event.target.files;
    setUploadedProfileImage(profile_image);
    console.log(profile_image);
    console.log(profileRef);
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      profileImage: profile_image,
    }));
    if (!profile_image) {
      setError((prevError) => ({
        ...prevError,
        profileImageError: "Please upload your Profile Image!",
      }));
    }else{
      setError((prevError) => ({
        ...prevError,
        profileImageError: "",
      }));
    }
    
  };

  // for profile image upload event
  const cvChangedHandler = (event) => {
    const cv = event.target.files;
    setUploadedCV(cv);
    console.log(cv);
    console.log(cvRef);
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      enterCV: cv,
    }));
    if (!cv) {
      setError((prevError) => ({
        ...prevError,
        cvError: "Please upload your CV",
      }));
    }else{
      setError((prevError) => ({
        ...prevError,
        cvError: "",
      }));
    }
    
  };

  // for medical certificate upload event
  const medicalCertificateChangedHandler = (event) => {
    const selectFiles = event.target.files;
    setUploadedMedicalFiles(selectFiles);
    console.log(selectFiles);
    console.log(file1InputRef);
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      medicalCertificate: selectFiles,
    }));
    if (!selectFiles) {
      setError((prevError) => ({
        ...prevError,
        medicalCertificateError: "Please upload your Medical Certificate!",
      }));
    }else{
      setError((prevError) => ({
        ...prevError,
        medicalCertificateError: "",
      }));
    }
    
  };

  // for MS MD medical certificate upload event
  const msmdCertificateChangedHandler = (event) => {
    const selectedFiles = event.target.files;
    setUploadedMDFiles(selectedFiles);
    // console.log(selectFiles);
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      msmdCertificate: selectedFiles,
    }));
    if (!selectedFiles) {
      setError((prevError) => ({
        ...prevError,
        msmdCertificateError: "Please upload your Certificate!",
      }));
    }else{
      setError((prevError) => ({
        ...prevError,
        msmdCertificateError: "",
      }));
    }
    
  };

  // for checkbox event
  const regFormChangedHandler = (event) => {
    const checked = event.target.value;
    console.log(checked);
    if(isChecked === true){
        setIsChecked(false);
        setError((prevError) => ({
            ...prevError,
            declaractionError: "Please click on checkbox to accept the declaration!",
        }));
    }
    else {
        setIsChecked(true);
        setError((prevError) => ({
            ...prevError,
            declaractionError: " ",
        }));
    }
    
  };

  // for Permanent Address events
  const homeChangeHandler = (event) => {
    const homeAddress = event.target.value;
    console.log(homeAddress);
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      enterAddress: homeAddress,
    }));

    if (homeAddress.trim().length === 0) {
        
      setError((prevError) => ({
        ...prevError,
        addressError: "Please enter your address!",
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        addressError: "",
      }));
    }
  };

  // for Clinic Address events
  const clinicChangeHandler = (event) => {
    const clinicAddress = event.target.value;
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      enterClinicAddress: clinicAddress,
    }));
    if (clinicAddress.trim().length === 0) {
      setError((prevError) => ({
        ...prevError,
        clinicAddressError: "Please enter your address!",
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        clinicAddressError: "",
      }));
    }
  };

  //Speciality
  const doctorChangeHandler = (event) => {
    const selectDoctor = event.target.value;
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      enterDoctor: selectDoctor,
    }));
    if (selectDoctor.length === 0) {
      setError((prevError) => ({
        ...prevError,
        doctorError: "Please select your Specialist!",
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        doctorError: "",
      }));
    }
  };

  // for Qualification events
  const qualificationChangeHandler = (event) => {
    const qualification = event.target.value;
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      enterQualification: qualification,
    }));
    if (qualification.trim().length === 0) {
      setError((prevError) => ({
        ...prevError,
        qualificationError: "Please enter your address!",
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        qualificationError: "",
      }));
    }
  };

  // for Designation events
  const designationChangeHandler = (event) => {
    const designation = event.target.value;
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      enterDesignation: designation,
    }));
    if (designation.trim().length === 0) {
      setError((prevError) => ({
        ...prevError,
        designationError: "Please enter your address!",
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        designationError: "",
      }));
    }
  };

  // for Registration number events
  const registrationNumberChangeHandler = (event) => {
    const regNumber = event.target.value;
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      enterRegNumber: regNumber,
    }));
    if (regNumber.trim().length === 0) {
      setError((prevError) => ({
        ...prevError,
        regNumberError: "Please enter your Registration number!",
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        regNumberError: "",
      }));
    }
  };

  // show model events
  const modalCloseHandler = (event) => {
    event.preventDefault();
    setIsFormSubmitted(false);
  };

  // Function to focus on the field with an error
  const focusOnErrorField = (fieldName) => {
    
    if (fieldName === 'name') {
      console.log(fieldName);
      console.log(nameRef.current);
      nameRef.current.focus();
    }else if (fieldName === 'email') {
      emailRef.current.focus();
    }else if (fieldName === 'phone') {
      phoneRef.current.focus();
    }else if (fieldName === 'age') {
      ageRef.current.focus();
    }else if (fieldName === 'address') {
      addressRef.current.focus();
    }else if (fieldName === 'clinic') {
      clinicRef.current.focus();
    }else if (fieldName === 'regNumber') {
      regNumberRef.current.focus();
    }else if (fieldName === 'speciality') {
      doctorRef.current.focus();
    }else if (fieldName === 'qualification') {
      qualificationRef.current.focus();
    }else if (fieldName === 'designation') {
      designationRef.current.focus();
    }else if (fieldName === 'medical') {
      file1InputRef.current.focus();
    }else if (fieldName === 'other_certificate') {
      file2InputRef.current.focus();
    }else if (fieldName === 'profile') {
      profileRef.current.focus();
    }else if (fieldName === 'cv') {
      cvRef.current.focus();
    }else if (fieldName === 'declaration') {
      declarationRef.current.focus();
    }
    // Focus on other fields as needed
  };

  // useEffect(() => {
  //   if (errorField) {
  //     focusOnErrorField(errorField);
  //   }
  // }, [errorField]);

  // for submit button events
  const doctorRegistrationSubmitHandler = async (event) => {
    event.preventDefault();
    
    if (error.nameError) {
      focusOnErrorField('name');
      setError((prevError) => ({
        ...prevError,
        nameError: "Please enter your full name!",
      }));
    } else if (appointmentInput.enterName.trim().length === 0) {
      focusOnErrorField('name');
      setError((prevError) => ({
        ...prevError,
        nameError: "Name field is required!",
      }));
    } else if (error.emailError) {
      focusOnErrorField('email');
      setError((prevError) => ({
        ...prevError,
        emailError: "Please enter your email address!",
      }));
    } else if (appointmentInput.enterEmail.length === 0) {
      focusOnErrorField('email');
      setError((prevError) => ({
        ...prevError,
        emailError: "Email address field is required!",
      }));
    } else if (error.numberError) { 
      focusOnErrorField('phone');
      setError((prevError) => ({
        ...prevError,
        numberError: "Please enter your phone number!",
      }));
    } else if (appointmentInput.enterNumber.trim().length === 0) {
      focusOnErrorField('phone');
      setError((prevError) => ({
        ...prevError,
        numberError: "Phone number field is required!",
      }));
    } else if (error.ageError) {
      focusOnErrorField('age');
        setError((prevError) => ({
          ...prevError,
          ageError: "Please enter your Date of birth!",
        }));
    } else if (appointmentInput.enterDob.length === 0) {
      focusOnErrorField('age');
        setError((prevError) => ({
          ...prevError,
          ageError: "Date of Birth field is required!",
        }));
    } else if (error.addressError) {
      focusOnErrorField('address');
        setError((prevError) => ({
            ...prevError,
            addressError: "Please enter your permanent address!",
        }));
    } else if (appointmentInput.enterAddress.length === 0) {
      focusOnErrorField('address');
        setError((prevError) => ({
          ...prevError,
          addressError: "Permanent address field is required!",
        }));
    } else if (error.clinicAddressError) {
      focusOnErrorField('clinic');
        setError((prevError) => ({
            ...prevError,
            clinicAddressError: "Please enter your Hospital/Clinic address!",
        }));
    } else if (appointmentInput.enterClinicAddress.length === 0) {
      focusOnErrorField('clinic');
        setError((prevError) => ({
          ...prevError,
          clinicAddressError: "Hospital/Clinic address field is required!",
        }));
    } else if (error.doctorError) {
      focusOnErrorField('speciality');
        setError((prevError) => ({
            ...prevError,
            doctorError: "Please select your specialisation!",
        }));
    } else if (appointmentInput.enterDoctor.length === 0) {
      focusOnErrorField('speciality');
        setError((prevError) => ({
          ...prevError,
          doctorError: "Speciality field is required!",
        }));
    } else if (error.qualificationError) {
      focusOnErrorField('qualification');
        setError((prevError) => ({
            ...prevError,
            qualificationError: "Please enter your qualification!",
        }));
    } else if (appointmentInput.enterQualification.length === 0) {
      focusOnErrorField('qualificaton');
        setError((prevError) => ({
          ...prevError,
          qualificationError: "Qualification field is required!",
        }));
    } else if (error.designationError) {
      focusOnErrorField('designation');
        setError((prevError) => ({
            ...prevError,
            designationError: "Please enter your designation!",
        }));
    } else if (appointmentInput.enterDesignation.length === 0) {
      focusOnErrorField('designation');
        setError((prevError) => ({
          ...prevError,
          designationError: "Designation field is required!",
        }));
    } else if (error.regNumberError) {
      focusOnErrorField('regNumber');
        setError((prevError) => ({
            ...prevError,
            regNumberError: "Please enter your registration number!",
        }));
    } else if (appointmentInput.enterRegNumber.length === 0) {
      focusOnErrorField('regNumber');
        setError((prevError) => ({
          ...prevError,
          regNumberError: "Registration number field is required!",
        }));
    } else if (file1InputRef.current.value === "") {
      focusOnErrorField('medical');
      setError((prevError) => ({
        ...prevError,
        medicalCertificateError: "Please upload Medical Certificate file!",
      }));
    } else if (file2InputRef.current.value === "") {
      focusOnErrorField('other_certificate');
        setError((prevError) => ({
          ...prevError,
          msmdCertificateError: "Please upload required file!",
        }));
    } else if (profileRef.current.value === "") {
      focusOnErrorField('profile');
      setError((prevError) => ({
        ...prevError,
        profileImageError: "Please upload Your Profile Image!",
      }));
    } else if (cvRef.current.value === "") {
      focusOnErrorField('cv');
      setError((prevError) => ({
        ...prevError,
        cvError: "Please upload your CV",
      }));
    } else if (isChecked === false) {
      focusOnErrorField('declaration');
        setError((prevError) => ({
          ...prevError,
          declarationError: "Please click on checkbox to accept the declaration!",
        }));
    } else {
      setAppointmentInput({
        enterName: "",
        enterEmail: "",
        enterDob: "",
        enterNumber: "",
        enterAddress: "",
        enterClinicAddress: "",
        enterDoctor:"",
        enterQualification: "",
        enterDesignation: "",
        enterRegNumber: "",
        gender: "male",
        medicalCertificate: [],
        msmdCertificate: [],
        enterCV: [],
        profileImage: [],
      });

      // Use the uploadedFiles variable here
      console.log(uploadedMedicalFiles);
      setUploadedMedicalFiles([]);
      setUploadedMDFiles([]);
      setUploadedProfileImage([]);
      setUploadedCV([]);
      setIsChecked(false);

      // Reset file input value
      if (file1InputRef.current) {
        file1InputRef.current.value = "";
      }
      if (file2InputRef.current) {
        file2InputRef.current.value = "";
      }
      if (profileRef.current) {
        profileRef.current.value = "";
      }
      if (cvRef.current) {
        cvRef.current.value = "";
      }
    }

    try {
      // appointmentInput.append('file', uploadedFiles);
      console.log(appointmentInput);
      setIsLoading(true);
      await Baseurls
        .post("/getdoctor", appointmentInput, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
          },
          mode: "cors", 
        })
        .then((res) => {

          console.log(res.data);
          setIsLoading(false);
          if (res.data.status === "true"){
            console.log("status: ");
            console.log(res.data.status);
            Swal.fire({  
              title: "Registered Successfully!",  
              text: "Please verify your email for further process, we'll connect with you soon!",
              icon: 'success'
            });
            setTimeout(() => {
              navigate('/');
          }, 500);
          } else{
            Swal.fire({  
              title: "Submission Failed!",  
              text: res.data.message,
              icon: 'error'
            });
          }
          //  setIsFormSubmitted(true);
        }) // Handle the response from backend here
        .catch((err) => {
          // setErrorMessage("Unable to fetch user list");
          setIsLoading(false);
        }); // Catch errors if any;
    } catch (e) {}
  };

  const titleHeading = "Guidelines to record video";
  const texOne = (
    <p style={{ fontSize: "12px" }}>
      <b>1:</b> To begin recording, just click on 'Start Video Recording'.
    </p>
  );
  const textTwo = (
    <p style={{ fontSize: "12px" }}>
      <b>2:</b> Next, click "Turn my camera ON" to allow access to your camera.
    </p>
  );
  const textThree = (
    <p style={{ fontSize: "12px" }}>
      <b>3:</b> Press the red-color record button to start recording your video.
    </p>
  );
  const textFour = (
    <p style={{ fontSize: "12px" }}>
      <b>4:</b> Your video can be of up to 2 minutes long.
    </p>
  );
  const textFive = (
    <p style={{ fontSize: "12px" }}>
      <b>5:</b> To stop recording, simply press the red-color stop record button.
    </p>
  );
  const textSix = (
    <p style={{ fontSize: "12px" }}>
      <b>6:</b> Once you stop the recording or reach the time limit, the video will be saved automatically.
    </p>
  );
  const textSeven = (
    <p style={{ fontSize: "12px" }}>
      <b>7:</b> If you want to keep a copy, click "Download Recording" to download the recorded video.
    </p>
  );

  return (
    <Fragment>
      <Form
        className={classes["appointment-one__form"]}
        onSubmit={doctorRegistrationSubmitHandler}
      >
        <Row className={classes["align-items-end"]}>
          <Col xs="12" lg="12">
            <label className={classes["appointment-one__label"]}>
              Full Name *
            </label>
            <div className={classes["appointment-one__form-group"]}>
              <div className={classes["appointment-one__icon"]}>
                <FaUserAlt className={classes["material-icons"]} />
              </div>
              <input
                type="text"
                name="name"
                className={classes["appointment-one__field"]}
                placeholder="Full name"
                onChange={nameChangeHandler}
                value={appointmentInput.enterName}
                ref={nameRef}
              />
              {error.nameError && (
                <p className={classes.errorClasses}>{error.nameError}</p>
              )}
            </div>
          </Col>
          <Col xs="12" lg="12">
            <label className={classes["appointment-one__label"]}>
              Email Address *
            </label>
            <div className={classes["appointment-one__form-group"]}>
              <div className={classes["appointment-one__icon"]}>
                <FaEnvelope className={classes["material-icons"]} />
              </div>
              <input
                type="text"
                name="email"
                className={classes["appointment-one__field"]}
                placeholder="Email Address"
                onChange={emailChangeHandler}
                value={appointmentInput.enterEmail}
                ref={emailRef}
              />
              {error.emailError && (
                <p className={classes.errorClasses}>{error.emailError}</p>
              )}
            </div>
          </Col>
          <Col xs="12" lg="12">
            <label className={classes["appointment-one__label"]}>
              Phone Number *
            </label>
            <div className={classes["appointment-one__form-group"]}>
              <div className={classes["appointment-one__icon"]}>
                <FaPhoneAlt className={classes["material-icons"]} />
              </div>
              <input
                type="text"
                maxLength={10}
                name="phone"
                className={`${classes["appointment-one__field"]}`}
                placeholder="Enter number"
                onChange={numberChangeHandler}
                value={appointmentInput.enterNumber}
                ref={phoneRef}
              />
              {error.numberError && (
                <p className={classes.errorClasses}>{error.numberError}</p>
              )}
            </div>
          </Col>
          <Col xs="12" lg="12">
            <label className={classes["appointment-one__label"]}>Date of Birth *</label>
            <div className={classes["appointment-one__form-group"]}>
              <div className={classes["appointment-one__icon"]}>
                <AiFillCalendar className={classes["material-icons"]} />
              </div>
              <input
                type="date"
                name="dob"
                minLength={2}
                maxLength={2}
                className={`${classes["appointment-one__field"]} ${classes["appointment-age"]}`}
                placeholder="Enter your Date of Birth"
                onChange={ageChangeHandler}
                value={appointmentInput.enterDob}
                ref={ageRef}
              />
              {error.ageError && (
                <p className={classes.errorClasses}>{error.ageError}</p>
              )}
            </div>
          </Col>
          {/* <Col xs="12" lg="12">
            <label className={classes["appointment-one__label"]}>Gender</label>
            <div className={classes["appointment-one__form-group"]}>
              <div className={classes["appointment-one__icon"]}>
                <FaUserAlt className={classes["material-icons"]} />
              </div>
              <div className={classes["appointment-one__switch-wrap"]}>
                <input
                  type="hidden"
                  name="gender"
                  value={male ? "male" : female ? "female" : ""}
                />
                <button
                  type="button"
                  onClick={maleClickHandler}
                  value={male}
                  className={`${male ? classes.active : ""} ${
                    classes["appointment-one__switch"]
                  }`}
                >
                  Male
                </button>
                <button
                  type="button"
                  onClick={femaleClickHandler}
                  value={female}
                  className={`${female ? classes.active : ""} ${
                    classes["appointment-one__switch"]
                  }`}
                >
                  Female
                </button>
              </div>
            </div>
          </Col> */}
          <Col xs="12" lg="12">
            <label className={classes["appointment-one__label"]}>
              Parmanent Address *
            </label>
            <div className={`${classes["appointment-one__form-group"]}`}>
              <div className={classes["appointment-one__icon"]}>
                    <AiFillHome className={classes["material-icons"]} />
              </div>
              <input
                type="text"
                name="home"
                className={`${classes["appointment-one__field"]} ${classes["appointment-age"]}`}
                placeholder="Parmanent Address"
                onChange={homeChangeHandler}
                value={appointmentInput.enterAddress}
                ref={addressRef}
              />
              {error.addressError && (
                <p className={classes.errorClasses}>{error.addressError}</p>
              )}
            </div>
          </Col>
          <Col xs="12" lg="12">
            <label className={classes["appointment-one__label"]}>
              Hospital/Clinic Address *
            </label>
            <div className={`${classes["appointment-one__form-group"]}`}>
                <div className={classes["appointment-one__icon"]}>
                    <FaClinicMedical className={classes["material-icons"]} />
              </div>
              <input
                type="text"
                name="home"
                className={`${classes["appointment-one__field"]} ${classes["appointment-age"]}`}
                placeholder="Hospital/Clinic Address"
                onChange={clinicChangeHandler}
                value={appointmentInput.enterClinicAddress}
                ref={clinicRef}
              />
              {error.clinicAddressError && (
                <p className={classes.errorClasses}>{error.clinicAddressError}</p>
              )}
            </div>
          </Col>
          <Col xs="12" lg="12">
            <label className={classes["appointment-one__label"]}>
              Speciality *
            </label>
            <div className={`${classes["appointment-one__form-group"]}`}>
              <div className={classes["appointment-one__icon"]}>
                    <FaUserNurse className={classes["material-icons"]} />
              </div>
              <select
                className={`${classes["appointment-one__field"]} ${classes["selectPickerItem"]}`}
                onChange={doctorChangeHandler}
                value={appointmentInput.enterDoctor}
                ref={doctorRef}
              >
                <option value="" defaultValue>
                  Select Specialist
                </option>
                {Select_Doctor.map((option) => {
                  return (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  );
                })}
              </select>
              {error.doctorError && (
                <p className={classes.errorClasses}>{error.doctorError}</p>
              )}
            </div>
          </Col>
          <Col xs="12" lg="12">
            <label className={classes["appointment-one__label"]}>
              Qualifications *
            </label>
            <div className={`${classes["appointment-one__form-group"]}`}>
              <div className={classes["appointment-one__icon"]}>
                    <FaMedal className={classes["material-icons"]} />
              </div>
            <input
                type="text"
                name="qualifications"
                className={`${classes["appointment-one__field"]} ${classes["appointment-age"]}`}
                placeholder="Qualifications"
                onChange={qualificationChangeHandler}
                value={appointmentInput.enterQualification}
                ref={qualificationRef}
              />
              {error.qualificationError && (
                <p className={classes.errorClasses}>{error.qualificationError}</p>
              )}
            </div>
          </Col>
          <Col xs="12" lg="12">
            <label className={classes["appointment-one__label"]}>
              Designation *
            </label>
            <div className={classes["appointment-one__form-group"]}>
              <div className={classes["appointment-one__icon"]}>
                    <AiTwotoneCrown className={classes["material-icons"]} />
              </div>
            <input
                type="text"
                name="designation"
                className={`${classes["appointment-one__field"]} ${classes["appointment-age"]}`}
                placeholder="Designation"
                onChange={designationChangeHandler}
                value={appointmentInput.enterDesignation}
                ref={designationRef}
              />
              {error.designationError && (
                <p className={classes.errorClasses}>{error.designationError}</p>
              )}
            </div>
          </Col>
          <Col xs="12" lg="12">
            <label className={classes["appointment-one__label"]}>
            Registration Number & State in Which Registered *
            </label>
            <div className={classes["appointment-one__form-group"]}>
              <div className={classes["appointment-one__icon"]}>
                <FaIdCard className={classes["material-icons"]} />
              </div>
              <input
                type="text"
                maxLength={12}
                name="id_number"
                className={`${classes["appointment-one__field"]}`}
                placeholder="Enter your Registration Number"
                onChange={registrationNumberChangeHandler}
                value={appointmentInput.enterRegNumber}
                ref={regNumberRef}
              />
              {error.regNumberError && (
                <p className={classes.errorClasses}>{error.regNumberError}</p>
              )}
            </div>
          </Col>
          <Col xs="12" lg="12">
            <label className={classes["appointment-one__label"]}>
                Please Upload your Profile Image *
            </label>
            <div
              className={`${classes["appointment-one__form-group"]} ${classes["appointment-one-form-file"]}`}
            >
              <div className={classes["appointment-one__icon"]}>
                <FaFileSignature className={classes["material-icons"]} />
              </div>
              <input
                type="file"
                name="profileImage"
                className={`${classes["appointment-one__field2"]} ${classes["appointment-file"]} form-control`}
                onChange={profileImageChangedHandler}
                // value={uploadedFiles}
                multiple
                accept="image/png, image/jpeg, image/jpg"
                ref={profileRef}
              />
              <label htmlFor="" className={`${classes["file-label"]}`}>
                Files allowed: .jpg, .jpeg, .png
              </label>
              {error.profileImageError && (
                <p className={classes.errorClasses}>{error.profileImageError}</p>
              )}
            </div>
          </Col>
          <Col xs="12" lg="12">
            <label className={classes["appointment-one__label"]}>
                Please attach your CV *
            </label>
            <div
              className={`${classes["appointment-one__form-group"]} ${classes["appointment-one-form-file"]}`}
            >
              <div className={classes["appointment-one__icon"]}>
                <FaFileSignature className={classes["material-icons"]} />
              </div>
              <input
                type="file"
                name="cv"
                className={`${classes["appointment-one__field2"]} ${classes["appointment-file"]} form-control`}
                onChange={cvChangedHandler}
                // value={uploadedFiles}
                multiple
                accept="application/pdf"
                ref={cvRef}
              />
              <label htmlFor="" className={`${classes["file-label"]}`}>
                Files allowed: .pdf
              </label>
              {error.cvError && (
                <p className={classes.errorClasses}>{error.cvError }</p>
              )}
            </div>
          </Col>
          <Col xs="12" lg="12">
            <label className={classes["appointment-one__label"]}>
                Please attach your Medical Council Certificate *
            </label>
            <div
              className={`${classes["appointment-one__form-group"]} ${classes["appointment-one-form-file"]}`}
            >
              <div className={classes["appointment-one__icon"]}>
                <FaFileSignature className={classes["material-icons"]} />
              </div>
              <input
                type="file"
                name="medicalCertificate"
                className={`${classes["appointment-one__field2"]} ${classes["appointment-file"]} form-control`}
                onChange={medicalCertificateChangedHandler}
                // value={uploadedFiles}
                multiple
                accept="application/pdf image/png, image/jpeg, image/jpg"
                ref={file1InputRef}
              />
              <label htmlFor="" className={`${classes["file-label"]}`}>
                Files allowed: .pdf, .jpg, .jpeg, .png
              </label>
              {error.medicalCertificateError && (
                <p className={classes.errorClasses}>{error.medicalCertificateError}</p>
              )}
            </div>
          </Col>
          <Col xs="12" lg="12">
            <label className={classes["appointment-one__label"]}>
                Please attach your MS, MD Certificate *
            </label>
            <div
              className={`${classes["appointment-one__form-group"]} ${classes["appointment-one-form-file"]}`}
            >
              <div className={classes["appointment-one__icon"]}>
                <FaFileSignature className={classes["material-icons"]} />
              </div>
              <input
                type="file"
                name="files"
                className={`${classes["appointment-one__field2"]} ${classes["appointment-file"]} form-control`}
                onChange={msmdCertificateChangedHandler}
                // value={uploadedFiles}
                multiple
                accept="application/pdf image/png, image/jpeg, image/jpg"
                ref={file2InputRef}
              />
              <label htmlFor="" className={`${classes["file-label"]}`}>
                Files allowed: .pdf, .jpg, .jpeg, .png
              </label>
              {error.msmdCertificateError && (
                <p className={classes.errorClasses}>{error.msmdCertificateError}</p>
              )}
            </div>
          </Col>
          <Col xs="12" lg="12">
            <div className={classes["appointment-one__form-group"]}>
                <div className={classes["d-inline"]}>
                    <Checkbox 
                        type="checkbox"
                        name="regForm"
                        className=""
                        onChange={regFormChangedHandler}
                        value={isChecked}
                        ref={declarationRef}
                    />&nbsp;
                    Declaration: I declare that the above details are correct. I shall abide by the regulations of the organization in force and any subsequent amendments made from time to time.
                </div>
            
            {error.declaractionError && (
                <p className={classes.errorClasses}>{error.declaractionError}</p>
            )}
            </div>
          </Col>
          <Col xs="12" xl="4">
            <Button type="submit" className={classes["appointment-one__btn"]}>
            Register
            </Button>
            {isLoading && <LoadingSpinner /> }
            {isFormSubmitted && (
              <Model name="appointmentModal" onClose={modalCloseHandler}>
                {{
                  title: "Success",
                  text: "Timing: 9AM to 5PM, and if within 48 hrs opinion not attended, then no charges will be taken",
                }}
              </Model>
            )}
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

export default DoctorDetail;
