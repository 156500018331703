import React, { Fragment } from "react";

// css
import classes from "./InputText.module.css";

const InputText = (props) => {
  const { type, name, placeholder, onChange, enterValue, enterError, maxLength, ref } = props;

  return (
    <Fragment>
      <div className={classes["form-group"]}>
        <input
          type={type}
          maxLength={maxLength}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={enterValue}
          className={`form-control`}
          ref={ref}
        />
        {enterError && <p className={classes.error}>{enterError}</p>}
      </div>
    </Fragment>
  );
};

export default InputText;
