import React, { Fragment, useRef, useEffect, useState } from "react";
import { SecondMedicalOpinionData } from "./SecondMedicalOpinionData";
import Button from "../../SameLayout/Ui/Button/Button";

// image
// import Doc from "../../../assets/7.png";
import Doc from "../../../assets/Why-Choose-new.png";

// css
import { Col, Container, Row, Tabs, Tab, Image } from "react-bootstrap";
import classes from "./SecondMedicalOpinion.module.css";
import { useNavigate } from "react-router-dom";

const SecondMedicalOpinion = () => {
  const appointmentNavigation=useNavigate()
  const [isAppointment,setIsAppointment]=useState(false)
  const appointmentClickHandler=event=>{
    event.preventDefault()
    setIsAppointment(true)
  }
  useEffect(()=>{
    if(isAppointment){
      setIsAppointment(false)
      appointmentNavigation("/appointment")
      window.scrollTo(0,0)
    }
  },[isAppointment,appointmentNavigation])

  const columnRef = useRef(null);
  const [pageScrolled, setPageScrolled] = useState(false);

  useEffect(() => {
    const scrolledPageHandler = () => {
      if(!columnRef.current) return
      const column = columnRef.current;
      const columnTop = column.getBoundingClientRect().top;
      const screenHeight = window.innerHeight;
      const whenAppear = columnTop - screenHeight + 10;

      setPageScrolled(window.pageYOffset > whenAppear);
    };
    window.addEventListener("scroll", scrolledPageHandler);

    return () => {
      window.removeEventListener("scroll", scrolledPageHandler);
    };
  });

  const renderTabs = () => {
    return SecondMedicalOpinionData.map((data) => (
      <Tab
        eventKey={data.tabEventKey}
        title={data.tabTitle}
        key={data.id}
        className={classes["medical-opinion-tab"]}
      >
        <p>{data.tabParaOne}</p>
        <p>{data.tabParaTwo}</p>
          <Button type="button" onClick={appointmentClickHandler} className={classes["second-medical-button"]}>
            {data.tabButtonText}
          </Button>
      </Tab>
    ));
  };

  return (
    <Fragment>
      <section className={classes["why-second-medical"]}>
        <Container fluid="lg">
          <Row>
            <Col xs="12" lg="8" className={classes["column-height"]}>
              <div className={classes["medical-tab"]}>
                <div className={classes["block-title"]}>
                  <h2 className={classes["block-title__title"]}>
                  Ekauropinion: Why are we different? 
                  </h2>
                  <p className={classes["block-title__text"]}>
                  We offer second opinions on health care and medical cases through our online platform, ekauropinion, to help you make a more informed decision.
                  </p>
                </div>
                <div className={`${classes["medical-tab-bottom"]}`}>
                  <Tabs
                    justify
                    variant="pills"
                    defaultActiveKey="tab-1"
                    className={classes["medical-tab-links"]}
                  >
                    {renderTabs()}
                  </Tabs>
                </div>
              </div>
            </Col>
            <Col lg="4">
              <div
                className={`${pageScrolled ? classes.imageScroll : ""} ${
                  classes["blank-images"]
                } ${classes["display-none-box"]}`}
                ref={columnRef}
              >
                <Image src={Doc} alt="Doc" fluid />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default SecondMedicalOpinion;
