import React, { Fragment } from "react";
import MainNavigation from "./MainNavigation";
import { Outlet } from "react-router-dom";
import BottomToTop from "../SameLayout/BottomToTop/BottomToTop";
import Footer from "../SameLayout/Footer/Footer";

const RootPage = () => {
  return (
    <Fragment>
      <MainNavigation />
      <Outlet />
      <Footer />
      <BottomToTop />
    </Fragment>
  );
};

export default RootPage;
