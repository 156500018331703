import React, { Fragment, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { HealthCarouselItem } from "./HealthCarouselItem";
import { MobileHealthCarouselItem } from "./MobileHealthCarouselItem";
import Button from "../../SameLayout/Ui/Button/Button";
import { useMediaQuery } from 'react-responsive';
// import images
// import SlideOne from "../../../assets/1.jpg";

// css
import classes from "./BetterHealth.module.css";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";

const BetterHealth = () => {
  const [index, setIndex] = useState(0);

  const isMobile = useMediaQuery({ maxWidth: 768 }); // Define your own mobile breakpoint

  const HealthCarouselItems = isMobile ? MobileHealthCarouselItem : HealthCarouselItem;

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Fragment>
      <section className={classes["better-health-section"]}>
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          className={classes["carousel-slide-height"]}
        >
          {HealthCarouselItems.map((data, key) => {
            return (
              <Carousel.Item
              key={data.id}
              className={`${index === key ? classes["active-carousel"] : ""} ${
                classes["carousel-item-class"]
              }`}
              >
                <div className={classes.overlay}></div>
                <Image
                  className={`${classes.imageHeight} d-block w-100 img-fluid`}
                  src={data.carouselImage}
                  alt={data.imageAlt}
                  fluid
                />
                <Carousel.Caption className={classes.captionBg}>
                  <h2>{data.headingText}</h2>
                  <p>{data.paragraphText}</p>
                  <Link to='/getopinion'>
                    <Button type="button" className={classes["carousel-button"]}>
                      Get ekauropinion
                    </Button>
                  </Link>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </section>
    </Fragment>
  );
};

export default BetterHealth;
