import React, { Fragment } from 'react'

// css
import { Col, Container, Row } from 'react-bootstrap'
import classes from './VisionMission.module.css'

const VisionMission = () => {
  return (
    <Fragment>
        <section className={classes["vision-mission"]}>
            <Container fluid="lg">
                <Row>
                    <Col xs="12" lg="6">
                        <div className={classes["vision-mission-box"]}>
                            <h5>Vision</h5>
                            <p>"Redefining healthcare pathways by making expert second opinions accessible to all, ensuring every medical decision is informed, inderstood, and trusted."</p>
                        </div>
                    </Col>
                    <Col xs="12" lg="6">
                        <div className={`${classes["vision-mission-box"]} mb-0`}>
                            <h5>Mission</h5>
                            <p>"Empowering patients with clarity and confidence. Every voice deserves a second listen."</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </Fragment>
  )
}

export default VisionMission