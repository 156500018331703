import React, { Fragment } from "react";
// import MainNavigation from "../components/Root/MainNavigation";
// import Footer from "../components/SameLayout/Footer/Footer";
// import BottomToTop from "../components/SameLayout/BottomToTop/BottomToTop";

const Pages = () => {
  return (
    <Fragment>
      {/* <MainNavigation /> */}
      Pages
      {/* <Footer />
      <BottomToTop /> */}
    </Fragment>
  );
};

export default Pages;
