import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Button from "../../SameLayout/Ui/Button/Button";
import "animate.css";

// icons
import { MdDone } from "react-icons/md";

// css
import { Col, Container, Row } from "react-bootstrap";
import classes from "./OurSpeciality.module.css";
import "bootstrap/dist/js/bootstrap.bundle.js";

const OurSpeciality = () => {
  return (
    <Fragment>
      <section className={classes["why_we_need"]}>
        <Container fluid="lg">
          <Row>
            <Col xs="12" lg="6">
              <div className={classes["about-two__content my-auto"]}>
                <h3 className={classes["about-two__title"]}>
                Why It's Important?
                </h3>
                <p className={classes["about-two__text"]}>
                The fact is that everyone makes mistakes, and doctors are no different. Further, some doctors tend to be more conservative while others tend to be more aggressive. The results and recommendations of these doctors can therefore vary dramatically. The increasing number of patients is seeking second opinions following a diagnosis as a result. 
                </p>
                <p className={classes["about-two__text"]}>
                Obtaining a second opinion can still be beneficial, even if it confirms what you already know. After all, you will know that you have taken all the necessary measures to have the most accurate diagnosis and treatment plan. 
                </p>
                <Row>
                  <Col lg="6">
                    <ul className={classes["about-two__list"]}>
                      <li className={classes["about-two__list-item"]}>
                        <i className={classes["material-icons"]}>
                          <MdDone />
                        </i>
                        Upon diagnosis of a rare disease
                      </li>
                      <li className={classes["about-two__list-item"]}>
                        <i className={classes["material-icons"]}>
                          <MdDone />
                        </i>
                        You have not received a definitive diagnosis
                      </li>
                    </ul>
                  </Col>
                  <Col lg="6">
                    <ul className={classes["about-two__list"]}>
                      <li className={classes["about-two__list-item"]}>
                        <i className={classes["material-icons"]}>
                          <MdDone />
                        </i>
                        There is more than one “right” treatment
                      </li>
                      <li className={classes["about-two__list-item"]}>
                        <i className={classes["material-icons"]}>
                          <MdDone />
                        </i>
                        Preventing misdiagnosis
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs="12" lg="6">
              <div className={classes["about-two__box"]}>
                <Row>
                  <Col xs="12" md="6" lg="12">
                    <div className={`${classes["about-two__box-single"]}`}>
                      <div className={classes["border-one"]}> </div>
                      <div className={classes["border-two"]}> </div>
                      <i
                        className={
                          classes[
                            "clainc-icon-stethoscope-1 about-two__box-icon"
                          ]
                        }
                      ></i>
                      <h3 className={classes["about-two__box-title"]}>
                        <Link to="/" className={`${classes["about-one__btn"]}`}>
                          <Button type="button">Advanced Care</Button>
                        </Link>
                      </h3>
                      <p className={classes["about-two__box-text"]}>
                      Receive advanced, personalized healthcare from Ekauropinion, with expert recommendations based on the latest medical advancements and your specific needs.
                      </p>
                    </div>
                  </Col>
                  <Col xs="12" md="6" lg="12">
                    <div
                      className={`${classes["about-two__box-single"]} wow`}
                      fadeinup="true"
                      data-wow-duration="2s"
                    >
                      <div className={classes["border-one"]}></div>
                      <div className={classes["border-two"]}></div>
                      <i
                        className={
                          classes["clainc-icon-phone about-two__box-icon"]
                        }
                      ></i>
                      <h3 className={classes["about-two__box-title"]}>
                        <Link to="/" className={`${classes["about-one__btn"]}`}>
                          <Button type="button">Special Experts</Button>
                        </Link>
                      </h3>
                      <p className={classes["about-two__box-text"]}>
                      Expertise you can trust, backed by years of experience and a commitment to excellence.
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default OurSpeciality;
