import React, { Fragment, useState, useEffect, useRef } from "react";
import InputText from "../../SameLayout/Ui/InputText/InputText";
import Button from "../../SameLayout/Ui/Button/Button";
import Model from "../../SameLayout/Ui/Modal/Model";
import axios from "axios";
import Swal from 'sweetalert2';
import { Baseurls } from "../../SameLayout/BaseUrl/Baseurls";
import { useNavigate } from "react-router-dom";

// css
import { Col, Row } from "react-bootstrap";
import classes from "./Partners.module.css";


// API base url
// axios.defaults.baseURL = "http://127.0.0.1:8000/api";
// axios.defaults.baseURL = "https://etechdemo.com/secondopinionadmin/api";

const Partners = (props) => {

   const name = props.name;
   const url = props.geturl;

  const navigate = useNavigate();

  const [inputData, setInputData] = useState({
    enterName: "",
    enterEmail: "",
    enterNumber: "",
    enterBusinessName: "",
    enterBusinessType: "",
    enterBusinessAddress: "",
  });

  const [error, setError] = useState({
    nameError: "",
    emailError: "",
    numberError: "",
    businessNameError: "",
    businessTypeError: "",
    businessAddressError: ""
  });

  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const numberRef = useRef(null);
  const businessNameRef = useRef(null);
  const businessTypeRef = useRef(null);
  const businessAddressRef = useRef(null);

  const [isFormSubmitted, setIsFormSubmitted]=useState(false)
  const [isLoading, setIsLoading] = useState(false);

  const nameChangeHandler = (event) => {
    const nameValue = event.target.value;
    let letterAndSpace = /^[a-zA-Z\s]*$/;
    setInputData({
      ...inputData,
      enterName: nameValue,
    });
    if (nameValue.trim().length === 0) {
      setError({
        ...error,
        nameError: "Please enter your name!",
      });
    } else if (!letterAndSpace.test(nameValue)) {
      setError({
        ...error,
        nameError: "Please enter only alphabet!",
      });
    } else if (nameValue.trim().length < 4) {
      setError({
        ...error,
        nameError: "Please enter minimum 4 alphabet!",
      });
    } else {
      setError({
        ...error,
        nameError: "",
      });
    }
  };

  const emailChangeHandler = (event) => {
    const emailValue = event.target.value;
    let emailValidation = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    setInputData({
      ...inputData,
      enterEmail: emailValue,
    });
    if (emailValue.trim().length === 0) {
      setError({
        ...error,
        emailError: "Please enter your email address!",
      });
    } else if (!emailValidation.test(emailValue)) {
      setError({
        ...error,
        emailError: "Please enter valid email!",
      });
    } else {
      setError({
        ...error,
        emailError: "",
      });
    }
  };

  const numberChangeHandler = (event) => {
    const selectNumber = event.target.value;
    let onlyNumber = /^[0-9\b]+$/;
    setInputData((prevValue) => ({
      ...prevValue,
      enterNumber: selectNumber,
    }));
    if (selectNumber.trim().length === 0) {
      setError((prevError) => ({
        ...prevError,
        numberError: "Please enter your mobile number!",
      }));
    } else if (!onlyNumber.test(selectNumber)) {
      setError((prevError) => ({
        ...prevError,
        numberError: "Please enter only number!",
      }));
    } else if (
      selectNumber.trim().length < 10 ||
      selectNumber.trim().length > 10
    ) {
      setError((prevError) => ({
        ...prevError,
        numberError: "Please enter exactly 10 digits for the mobile number!",
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        numberError: "",
      }));
    }
  };

  const businessNameChangeHandler = (event) => {
    const bnameValue = event.target.value;
    setInputData({
      ...inputData,
      enterBusinessName: bnameValue,
    });
    if (bnameValue.trim().length === 0) {
      setError({
        ...error,
        businessNameError: "Please enter the name of your business!",
      });
    } else {
      setError({
        ...error,
        businessNameError: "",
      });
    }
  };
  const businessTypeChangeHandler = (event) => {
    const btypeValue = event.target.value;
    setInputData({
      ...inputData,
      enterBusinessType: btypeValue,
    });
    if (btypeValue.trim().length === 0) {
      setError({
        ...error,
        businessTypeError: "Please enter the type of your business!",
      });
    } else {
      setError({
        ...error,
        businessTypeError: "",
      });
    }
  };
  const businessAddressChangeHandler = (event) => {
    const baddressValue = event.target.value;
    setInputData({
      ...inputData,
      enterBusinessAddress: baddressValue,
    });
    if (baddressValue.trim().length === 0) {
      setError({
        ...error,
        businessAddressError: "Please enter the address of your business!",
      });
    } else {
      setError({
        ...error,
        businessAddressError: "",
      });
    }
  };



  // show model events
  const modalCloseHandler = (event) => {
    event.preventDefault();
    setIsFormSubmitted(false);
  };

  useEffect(() => {
    // Accessing nameRef.current safely after component is mounted
    console.log('useeffect');
    console.log(nameRef.current);
  }, []);

  const focusOnErrorField = (fieldName) => {
    
    if (fieldName === 'username') {
      console.log(fieldName);
      nameRef.current.focus();
    }else if (fieldName === 'email') {
      emailRef.current.focus();
    }else if (fieldName === 'number') {
      numberRef.current.focus();
    }else if (fieldName === 'businessname') {
      businessNameRef.current.focus();
    }else if (fieldName === 'businesstype') {
      businessTypeRef.current.focus();
    }else if (fieldName === 'businessaddress') {
      businessAddressRef.current.focus();
    }
  };

  const PartnersSubmitHandler = async (event) => {
    event.preventDefault();

    if (error.nameError) {
      focusOnErrorField('username');
      setError({
        ...error,
        nameError: "Please enter your valid name!",
      });
    } else if (inputData.enterName.length === 0) {
      focusOnErrorField('username');
      setError({
        ...error,
        nameError: "Please enter your name!",
      });
    } else if (error.emailError) {
      focusOnErrorField('email');
      setError({
        ...error,
        emailError: "Please enter your valid email address!",
      });
    } else if (inputData.enterEmail.length === 0) {
      focusOnErrorField('email');
      setError({
        ...error,
        emailError: "Please enter your email address!",
      });
    } else if (error.numberError) {
      focusOnErrorField('number');
      setError({
        ...error,
        numberError: "Please enter your valid phone number!",
      });
    } else if (inputData.enterNumber.length === 0) {
      focusOnErrorField('number');
      setError({
        ...error,
        numberError: "Please enter your phone number!",
      });
    } else if (error.businessNameError) {
      focusOnErrorField('businessname');
      setError({
        ...error,
        businessNameError: "Please enter the name of your business!",
      });
    } else if (inputData.enterBusinessName.length === 0) {
      focusOnErrorField('businessname');
      setError({
        ...error,
        businessNameError: "Please enter the name of your business!",
      });
    } else if (error.businessTypeError) {
      focusOnErrorField('businesstype');
      setError({
        ...error,
        businessTypeError: "Please enter the type of your business!",
      });
    } else if (inputData.enterBusinessType.length === 0) {
      focusOnErrorField('businesstype');
      setError({
        ...error,
        businessTypeError: "Please enter the type of your business!",
      });
    } else if (error.businessAddressError) {
      focusOnErrorField('businessaddress');
      setError({
        ...error,
        businessAddressError: "Please enter the address of your business!",
      });
    } else if (inputData.enterBusinessAddress.length === 0) {
      focusOnErrorField('businessaddress');
      setError({
        ...error,
        businessAddressError: "Please enter the address of your business!",
      });
    } else {
      setInputData({
        enterName: "",
        enterEmail: "",
        enterNumber : "",
        enterBusinessName: "",
        enterBusinessType: "",
        enterBusinessAddress: "",
      });

      try {
        // appointmentInput.append('file', uploadedFiles);
        console.log(inputData);
        setIsLoading(true);
        await Baseurls
          .post("/partners", inputData, {
            headers: {
              "Access-Control-Allow-Origin": '*',
              "Content-Type": "multipart/form-data",
              
            },
            mode: "cors",
          }).then((res) => {

            const res_data = res.data;
            const message = res_data.message;
            setIsLoading(false);
            console.log(res_data);
            if (res_data.status === "true") {
  
              const data = res_data.data;
              const token = data.user_token;
              const email = data.email;
  
              Swal.fire({  
                title: "You're registered successfully!",  
                text: 'Get ekauropinion',
                icon: 'success'
              }); 
            }else if(message == "Your email is already registered, please try with another email."){
              Swal.fire({  
                title: "Already Registration!",  
                text: "You are already registered with us!",
                icon: 'error'
              });
            }else{
  
            }
          }) // Handle the response from backend here
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          }); // Catch errors if any;
      } catch (e) {
        console.log(e);
      }
      
    }
  };

  return (
    <Fragment>
      <form
        method="post"
        id="contact-form"
        onSubmit={PartnersSubmitHandler}
        className={classes["contact-one__form"]}
      >
        <Row>
          <Col xs="12" md="12">
            <div className={classes["form-group"]}>
              <input
                type="text"
                name="username"
                placeholder="Full name"
                className={`form-control`}
                onChange={nameChangeHandler}
                value={inputData.enterName}
                ref={nameRef}
              />
              {error.nameError && (
                <p className={classes.error}>{error.nameError}</p>
              )}
            </div>
          </Col>
          <Col xs="12" md="12">
            <div className={classes["form-group"]}>
              <input
                type="text"
                name="email"
                placeholder="Email address"
                className={`form-control`}
                onChange={emailChangeHandler}
                value={inputData.enterEmail}
                ref={emailRef}
              />
              {error.emailError && (
                <p className={classes.error}>{error.emailError}</p>
              )}
            </div>
          </Col>
          <Col xs="12" lg="12">
            <div className={classes["form-group"]}>
              <input
                type="text"
                name="number"
                maxLength="10"
                placeholder="Enter phone number"
                className={`form-control`}
                onChange={numberChangeHandler}
                value={inputData.enterNumber}
                ref={numberRef}
              />
              {error.numberError && (
                <p className={classes.error}>{error.numberError}</p>
              )}
            </div>
          </Col>
          <Col xs="12" md="12">
            <div className={classes["form-group"]}>
              <input
                type="text"
                name="businessname"
                placeholder="Enter Your Business Name"
                className={`form-control`}
                onChange={businessNameChangeHandler}
                value={inputData.enterBusinessName}
                ref={businessNameRef}
              />
              {error.businessNameError && (
                <p className={classes.error}>{error.businessNameError}</p>
              )}
            </div>
          </Col>
          <Col xs="12" md="12">
            <div className={classes["form-group"]}>
              <input
                type="text"
                name="businesstype"
                placeholder="Enter Your Business Type"
                className={`form-control`}
                onChange={businessTypeChangeHandler}
                value={inputData.enterBusinessType}
                ref={businessTypeRef}
              />
              {error.businessTypeError && (
                <p className={classes.error}>{error.businessTypeError}</p>
              )}
            </div>
          </Col>
          <Col xs="12" md="12">
            <div className={classes["form-group"]}>
              <input
                type="text"
                name="businessaddress"
                placeholder="Enter Your Business Address"
                className={`form-control`}
                onChange={businessAddressChangeHandler}
                value={inputData.enterBusinessAddress}
                ref={businessAddressRef}
              />
              {error.businessAddressError && (
                <p className={classes.error}>{error.businessAddressError}</p>
              )}
            </div>
          </Col>
          <Col
            xs="12"
            md="6"
            className={`${classes["form-group"]} ${classes["message-btn"]}`}
          >
            <Button type="submit" name="submit-form">
              Register
            </Button>
            { 
              isFormSubmitted && (
                <Model onClose={modalCloseHandler}>
                {{
                  title: "Success",
                  text: "You are successfully registered, please verify your email to login!",
                }}
                </Model>
              )
            }
          </Col>
          {/* <Col
            xs="12"
            md="4"
            className={`${classes["form-group"]} ${classes["message-btn"]}`}
          >
            <span className={classes["sub-title d-block"]}>
                Already registered? 
            </span>
          </Col> */}
        </Row>
      </form>
    </Fragment>
  );
};

export default Partners;
