
export const SecondOpinionData = [
  {
    id: "so1",
    iconLinkText: "Patients looking for a second opinion",
    iconNumber: "88",
    iconSymbol: "%",
  },
  {
    id: "so2",
    iconLinkText: "People will leave with a “distinctly different” diagnosis",
    iconNumber: "21",
    iconSymbol: "%",},
  {
    id: "so3",
    iconLinkText: "Patients will learn that the original diagnosis was correct",
    iconNumber: "12",
    iconSymbol: "%",},
  {
    id: "so4",
    iconLinkText: "According to this, one in five patients they saw was misdiagnosed",
    iconNumber: "1",
    iconSymbol: "~life",},
];
