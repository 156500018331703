import React, { Fragment, useEffect, useState } from "react";
import BlogItemBox from "../BlogItemBox/BlogItemBox";
import { useNavigate, useParams } from "react-router-dom";

// image
import BlogOneImage from "../../../assets/blog/cervical_cancer_awareness.png";
import BlogTwoImage from "../../../assets/blog/Thyroid_awareness.png";
import BlogThreeImage from "../../../assets/blog/mindfullness.png";
import BlogFourImage from "../../../assets/blog/Decoding_The_Gut_Brain_Connection.png";
import BlogFiveImage from "../../../assets/blog/childhood_vaccinations.png";
import BlogSixImage from "../../../assets/blog/CELIAC_DISEASE.png";

// css
import { Container, Row } from "react-bootstrap";
import classes from "./BlogItem.module.css";

// import { Baseurl } from "../components/SameLayout/BaseUrl/Baseurls";
import {Baseurls} from "../../SameLayout/BaseUrl/Baseurls"

const BlogItem = () => {
  const blogNavigate = useNavigate();
  const { blogSlug, setBlogSlug} = useParams();
  const [blogList, setBlogList] = useState(false);
  const [blogPosts, setBlogPosts] = useState([]);

  const handleYourKidsClickHandler = (e) => {
    // e.preventDefault();
    setBlogList(true);
    setBlogSlug(e);
  };

  useEffect(() => {
    
    if (blogList) {
      setBlogList(false);
      // blogNavigate("/Uncovering-the-silent-killer");
      window.scrollTo(0, 0);
    }

    // Fetch data from the API
    const fetchData = async () => {
      try {
          Baseurls
          .get("/getBlog")
          .then((response) => {
            console.log(response.data);
            setBlogPosts(response.data);
          })
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    };

    fetchData();

  }, [blogList, blogNavigate]);

  return (
    <Fragment>
      <section className={`${classes["blog-one"]} ${classes["blog-page-one"]}`}>
        <Container fluid="lg">
          <Row>
            <BlogItemBox blog_item_data={blogPosts} />
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default BlogItem;
