// import images
// import SlideOne from "../../../assets/8.jpg";
import SlideTwo from "../../../assets/9.jpg";
import SlideOne from "../../../assets/2Banner.jpg";
import SlideThree from "../../../assets/3Banner.jpg";

// import MobileSlideOne from "../../../assets/3Banner.jpg";
import MobileSlideTwo from "../../../assets/mobile-banner2.jpg";
import MobileSlideThree from "../../../assets/3Banner.jpg";

export const HealthCarouselItem=[
    {
        id: "c1",
        carouselImage: SlideOne,
        headingText: "Mission",
        paragraphText: "Empowering patients with clarity and confidence. Every voice deserves a second listen.",
        imageAlt: "Slider One"
    },
    // {
    //     id: "m1",
    //     carouselImage: MobileSlideOne,
    //     headingText: "Get Ek aur Opinion Online",
    //     paragraphText: "Get the Healthcare Answers You Deserve. ekauropinion provides the clarity and confidence you need.",
    //     imageAlt: "Slider One"
    // },
    {
        id: "c2",
        carouselImage: SlideTwo,
        headingText: "Vision",
        paragraphText: "Redefining healthcare pathways by making expert second opinions accessible to all, ensuring every medical decision is informed, inderstood, and trusted.",
        imageAlt: "Slider Two"
    },
    // {
    //     id: "m2",
    //     carouselImage: MobileSlideTwo,
    //     headingText: "Get Ek aur Opinion Online",
    //     paragraphText: "Get the Healthcare Answers You Deserve. ekauropinion provides the clarity and confidence you need.",
    //     imageAlt: "Slider Two"
    // },
    {
        id: "c3",
        carouselImage: SlideThree,
        headingText: "Get ekauropinion Online",
        paragraphText: "Get the Healthcare Answers You Deserve. ekauropinion provides the clarity and confidence you need.",
        imageAlt: "Slider Three"
    },
    // {
    //     id: "m3",
    //     carouselImage: MobileSlideThree,
    //     headingText: "Get Ek aur Opinion Online",
    //     paragraphText: "Get the Healthcare Answers You Deserve. ekauropinion provides the clarity and confidence you need.",
    //     imageAlt: "Slider Three"
    // },
]