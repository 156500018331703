export   const HeaderMenuLinkData = [
    {
      id: "h1",
      navLinkTo: "/",
      name: "Home",
    },
    {
      id: "h2",
      navLinkTo: "/aboutus",
      name: "About",
    },
    {
      id: "h3",
      navLinkTo: "/getopinion",
      name: "ekauropinion",
    },
    {
      id: "h4",
      navLinkTo: "/expert",
      name: "Experts",
    },
    {
      id: "h5",
      navLinkTo: "/blog",
      name: "Blog",
    },
    {
      id: "h6",
      navLinkTo: "/faq",
      name: "FAQ",
    },
    {
      id: "h7",
      navLinkTo: "/contact",
      name: "Contact",
    },
    // {
    //   id: "h8",
    //   navLinkTo: "/getopinion",
    //   name: "Opinion",
    // },
    // {
    //   id: "h7",
    //   navLinkTo: "/",
    //   name: "",
    // },
  ];
