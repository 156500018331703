import React, { Fragment } from "react";
import Banner from "../components/SameLayout/Banner/Banner";
import BlogItem from "../components/BlogPage/BlogItem/BlogItem";

// images
// import InnerBanner from "../assets/18.jpg";
import InnerBanner from "../assets/Banner_Blog_Page.jpg";

const BlogPage = () => {
  return (
    <Fragment>
      <Banner
        bannerBackgroundImage={InnerBanner}
        pageTitle="Blog"
        parentPageTo="/"
        parentPageName="Home"
      />
      <BlogItem />
    </Fragment>
  );
};

export default BlogPage;
