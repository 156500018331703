import React, { Fragment, useState } from "react";
import InputText from "../../SameLayout/Ui/InputText/InputText";
import Button from "../../SameLayout/Ui/Button/Button";
import Model from "../../SameLayout/Ui/Modal/Model";
import LoadingSpinner from "../../SameLayout/Loader/LoadingSpinner";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { Baseurls } from "../../SameLayout/BaseUrl/Baseurls";

// css
import { Col, Row } from "react-bootstrap";
import classes from "../SecondOpinion.module.css";


const LoginForm = () => {

  //Loader-spinner
  const [isLoading, setIsLoading] = useState(false);

  const [inputData, setInputData] = useState({
    enterEmail: "",
    enterPassword: "",
  });

  const [error, setError] = useState({
    emailError: "",
    passwordError: "",
  });

  const [isFormSubmitted, setIsFormSubmitted]=useState(false);
  const navigate = useNavigate();

  const emailChangeHandler = (event) => {
    const emailValue = event.target.value;
    let emailValidation = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    setInputData({
      ...inputData,
      enterEmail: emailValue,
    });
    if (emailValue.trim().length === 0) {
      setError({
        ...error,
        emailError: "Please enter your email address!",
      });
    } else if (!emailValidation.test(emailValue)) {
      setError({
        ...error,
        emailError: "Please enter valid email!",
      });
    } else {
      setError({
        ...error,
        emailError: "",
      });
    }
  };

  const passwordChangeHandler = (event) => {
    const password = event.target.value;
    setInputData({
      ...inputData,
      enterPassword: password.trim(),
    });

    if (password.trim().length == 0) {
      setError({
        ...error,
        passwordError: "Please enter your password!",
      });
    } else {
      setError({
        ...error,
        passwordError: "",
      });
    }
  };

  // show model events
  const modalCloseHandler = (event) => {
    event.preventDefault();
    setIsFormSubmitted(false);
  };

  const LoginFormSubmitHandler = async (event) => {
    event.preventDefault();
    
    if (inputData.enterEmail.length === 0) {
      setError({
        ...error,
        emailError: "Please enter your email address!",
      });
    }else if (inputData.enterPassword.length === 0) {
      setError({
        ...error,
        passwordError: "Please enter your password!",
      });
    } else {
      setInputData({
        enterEmail: "",
        enterPassword: "",
      });
    }

    try {
      console.log(inputData);
      setIsLoading(true);
      console.log(isLoading);
      await Baseurls
        .post("/login", inputData, {
        // .get(`registration/${inputData.enterEmail}`, inputData, {
          headers: {
            "Access-Control-Allow-Origin": '*',
            "Content-Type": "multipart/form-data",
          },
          mode: "cors",
        })
        .then((response) => {

          
          const res_data = response.data;
          const message = res_data.message;
          setIsLoading(false);

          if (res_data.status === "true") {

            const data = res_data.data;
            const token = data.user_token;
            const email = data.email;

            Swal.fire({  
              title: "You're successfully logged in!",  
              text: 'Get ekauropinion',
              icon: 'success'
            }); 
            localStorage.clear();
            localStorage.setItem('user-token', token);
            localStorage.setItem('email', email);
            setTimeout(() => {
                navigate('/appointment');
            }, 500);
          }else if(message == "email not registered"){
            Swal.fire({  
              title: "Login Failed!",  
              text: "You are not registered with us, please register first to get ekauropinion!",
              icon: 'error'
            });
          }else if(message == "not verified"){
            Swal.fire({  
              title: "Login Failed!",  
              text: "Please Verify your email first to login!",
              icon: 'error'
            });
          }else{

          }
          
        }) // Handle the response from backend here
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        }); // Catch errors if any;
    } catch (e) {
      console.log(e);
    }

  };

  return (
    <Fragment>
      <form
        method="post"
        id="contact-form"
        onSubmit={LoginFormSubmitHandler}
        className={classes["contact-one__form"]}
      >
        <Row>
          <Col xs="12" md="12">
            <InputText
              type="text"
              name="email"
              placeholder="Email address"
              onChange={emailChangeHandler}
              enterValue={inputData.enterEmail}
              enterError={error.emailError}
            />
          </Col>
          <Col xs="12" md="12">
            <InputText
              type="password"
              name="password"
              placeholder="Password"
              onChange={passwordChangeHandler}
              enterValue={inputData.enterPassword}
              enterError={error.passwordError}
            />
          </Col>
          <Col
            xs="12"
            className={`${classes["form-group"]} ${classes["message-btn"]}`}
          >
            <Button type="submit" name="submit-form">
              Get ekauropinion
            </Button><br/><br />
            New User? <Link to="/register" className={classes['signup']}>Click here </Link>to register.
            {isLoading && <LoadingSpinner /> }
            { 
              isFormSubmitted && (
                <Model onClose={modalCloseHandler}>
                {{
                  title: "Success",
                  text: "You are not registered with us, please register first to get ek aur opinion!",
                }}
                </Model>
              )
            }
          </Col>
        </Row>
      </form>
    </Fragment>
  );
};

export default LoginForm;
