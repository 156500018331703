import React, { useState, useEffect, Fragment } from "react";
import BetterHealth from "../components/HomePage/BetterHealth/BetterHealth";
// import Appointment from "../components/HomePage/Appointment/Appointment";
import AboutUs from "../components/HomePage/AboutUs/AboutUs";
import SecondOpinion from "../components/HomePage/SecondOpinion/SecondOpinion";
import OurSpeciality from "../components/HomePage/OurSpeciality/OurSpeciality";
import SecondMedicalOpinion from "../components/HomePage/SecondMedicalOpinion/SecondMedicalOpinion";
import Testimonials from "../components/HomePage/Testimonials/Testimonials";
// import MainNavigation from "../components/Root/MainNavigation";
// import Footer from "../components/SameLayout/Footer/Footer";
// import BottomToTop from "../components/SameLayout/BottomToTop/BottomToTop";

import { Baseurls } from "../components/SameLayout/BaseUrl/Baseurls";

const HomePage = () => {

    const [check, setCheck] = useState(false);

    const visitorCount = () => {
      Baseurls
      .get("/visitors")
      .then((response) => {
        const count = response.data.data.count;
        localStorage.setItem('count', count);
      });

      setCheck(false);
  }
  useEffect(() => {
      visitorCount();
  }, [check]);


  return (
    <Fragment>
      {/* <MainNavigation /> */}
      <BetterHealth />
      {/* <Appointment /> */}
      <AboutUs />
      <SecondOpinion />
      <SecondMedicalOpinion />
      <OurSpeciality />
      <Testimonials />
      
      {/* <Footer />
      <BottomToTop /> */}
    </Fragment>
  );
};

export default HomePage;
