import React, { Fragment } from "react";
import Banner from "../components/SameLayout/Banner/Banner";
import Welcome from "../components/AboutPage/Welcome/Welcome";
import Treatment from "../components/AboutPage/Treatment/Treatment";
import VisionMission from "../components/AboutPage/VisionMission/VisionMission";
import AboutSecondOpinion from "../components/AboutPage/AboutSecondOpinion/AboutSecondOpinion";

// images
import InnerBanner from "../assets/11.jpg";

const AboutPage = () => {
  return (
    <Fragment>
      <Banner
        bannerBackgroundImage={InnerBanner}
        pageTitle="About Us"
        parentPageTo="/"
        parentPageName="Home"
      />
      <AboutSecondOpinion />
      <VisionMission />
      {/* <Treatment /> */}
      <Welcome />
    </Fragment>
  );
};

export default AboutPage;
