import React, { Fragment } from "react";
import Button from "../SameLayout/Ui/Button/Button";
import { useNavigate } from "react-router-dom";

// css
import { Col, Row } from "react-bootstrap";
import classes from "./SecondOpinion.module.css";
import { Container } from "react-bootstrap";

const SecondOpinion = () => {

  const navigate = useNavigate();

  const registerPageHandler = (event) => {

    setTimeout(() => {
                navigate('/register');
            }, 500);

  }

  return (
    <Fragment>
      <section className={`${classes["registration-one"]}`}>
      <Container>
          <Row className={`${classes["registration-one_header"]}`}>
            <Col xs="12" lg="12">
            <h3 className={classes["register-one__title"]}> “The greatest wealth is health.” </h3>
            <p className={classes["sub-title"]}>ekauropinion is a common, sometimes necessary step in helping you find the best care. When you’re getting a second opinion, you’re not being stubborn, closed-minded or challenging. Rather, you’re helping make sure you get the best care.<br /><br /></p>
            <p className={classes["sub-title"]}>
            If you think you’d like a second opinion, you’re likely wondering how to get one. Just register here via the below Registration form and get connected to our experts for the second opinion.</p>
            </Col>
          </Row>
          <Row>
            <p className={classes["sub-title"]}>
                Please register here to get ekauropinion.
            </p><br /><br />
            <Button 
                  type="button" 
                  name="register-button" 
                  onClick={registerPageHandler} 
                  className={classes["register-one__button"]}
                  >Click Here
            </Button>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default SecondOpinion;
