import React, { Fragment, useEffect, useRef } from "react";
import { HeaderMenuLinkData } from "./HeaderMenuLinkData";
import { NavLink } from "react-router-dom";

// css
import Nav from "react-bootstrap/Nav";
import classes from "./HeaderMenuLink.module.css";

const HeaderMenuLink = (props) => {
  const navbarRef = useRef(null);

  useEffect(() => {
    const handleNavLinkClick = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const navLinks = navbarRef.current.querySelectorAll("a");
    navLinks.forEach((link) => {
      link.addEventListener("click", handleNavLinkClick);
    });

    return () => {
      navLinks.forEach((link) => {
        link.removeEventListener("click", handleNavLinkClick);
      });
    };
  }, []);

  return (
    <Fragment>
      <Nav ref={navbarRef} className={`${classes["navbar-menu-link"]} ms-auto`}>
        {HeaderMenuLinkData.map((data) => {
          return (
            <NavLink
              key={data.id}
              to={data.navLinkTo}
              className={`${
                props.scrollPageSend
                  ? `${classes.scrollPageNavClass} scrolledPageGlobal`
                  : ""
              } ${classes["nav-link-classes"]}`}
              onClick={props.onClick}
            >
              {data.name}
            </NavLink>
          );
        })}
        <div
          className={`${props.scrollPageSend ? classes.navbarBar : ""} ${
            classes["navbar-bar"]
          }`}
        ></div>
      </Nav>
    </Fragment>
  );
};

export default HeaderMenuLink;
