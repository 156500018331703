import React, { Fragment, useState, useRef } from "react";
import Button from "../../SameLayout/Ui/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import Model from "../../SameLayout/Ui/Modal/Model";
import LoadingSpinner from "../../SameLayout/Loader/LoadingSpinner";
import VideoRecorder from "react-video-recorder";
import Swal from 'sweetalert2';
import { Baseurls } from "../../SameLayout/BaseUrl/Baseurls";

// icons
import {
  FaUserAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaFileSignature,
  FaIdCard,
} from "react-icons/fa";

import {
  AiFillCalendar,
  AiFillAudio,
  AiFillVideoCamera, 
} from "react-icons/ai";

// images
import RecordAudioGif from "../../../assets/24.gif";
import RecordVideoGif from "../../../assets/25.gif";

// css
import classes from "./AppointmentForm.module.css";
import { Col, Row, Image } from "react-bootstrap";
import Form from "react-bootstrap/Form";

const AppointmentForm = () => {

  // navigate
  const navigate = useNavigate();

  //Loader-spinner
  const [isLoading, setIsLoading] = useState(false);

  // select gender
  const [male, setMale] = useState(true);
  const [female, setFemale] = useState(false);

  // show doctor or specialist list
  const [subOptions, setSubOptions] = useState([]);

  // multi files upload
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const fileInputRef = useRef(null);
  // audio files
  const [audioRecord, setAudioRecord] = useState(false);
  const [permission, setPermission] = useState(false);
  const mediaRecorder = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [stream, setStream] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [audio, setAudio] = useState(null);
  const [audioGif, setAudioGif] = useState(false);
  // for audio files
  const [uploadAudioFile, setUploadAudioFiles] = useState();
  const [audioFiles, setAudioFiles] = useState(false);
  const audioFileRef = useRef(null);

  // video files
  const [videoRecord, setVideoRecord] = useState(false);
  console.log(RecordVideoGif);

  // show video model
  const [isVideoModelShow, setIsVideoModelShow] = useState(false);

  const [videoPermission, setVideoPermission] = useState(false);
  const liveVideoFeed = useRef(null);
  console.log(liveVideoFeed);
  const [videoRecordingStatus, setVideoRecordingStatus] = useState("inactive");
  const [isRecordingComplete, setIsRecordingComplete] = useState(false);
  const [videoStreamState, setVideoStreamState] = useState(null);
  const [videoChunks, setVideoChunks] = useState([]);
  const [recordedVideo, setRecordedVideo] = useState(null);
  console.log(recordedVideo);
  const [videoGif, setVideoGif] = useState(false);
  console.log(videoGif);
  const [videoData, setVideoData] = useState(null);
  const videoReff = useRef(null);

  // for video files
  const [videoFiles, setVideoFiles] = useState(false);
  const [uploadVideoFile, setUploadVideoFile] = useState();
  const videoFileRef = useRef(null);

  // modal state
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  // for local stored values
  const [email, setEmail] = useState(window.localStorage.getItem("email"));
  
  const [appointmentInput, setAppointmentInput] = useState({
    enterName: "",
    enterEmail: email,
    enterAge: "",
    enterIdType: "",
    enterIdCardNumber: "",
    enterDoctor: "",
    enterSubDoctor: "",
    enterNumber: "",
    enterMessage: "",
    gender: "male",
    file: [],
    audio: "",
    audioFile: [],
    video: "",
    videoFile: [],
  });

  const [error, setError] = useState({
    nameError: "",
    emailError: "",
    ageError: "",
    idProofError: "",
    idCardNumberError: "",
    doctorError: "",
    numberError: "",
    fileError: "",
    subDoctorError: "",
    messageError: "",
  });

  const ID_Proof = [
    "Aadhar Card",
    "PAN Card",
    "Voter ID Card",
    "Ration Card",
  ];

  const Select_Doctor = [
    "Cardiology Specialist",
    "Eyes care Specialist",
    "Pediatrics Specialist",
    "Hepatology Specialist",
  ];

  const getSubOptions = (mainOption) => {
    // Object containing main options and their corresponding sub-options
    const subOptionsData = {
      "Cardiology Specialist": [
        "Cardiology Specialist-1",
        "Cardiology Specialist-2",
        "Cardiology Specialist-3",
        "Cardiology Specialist-4",
      ],
      "Eyes care Specialist": [
        "Eyes care Specialist-1",
        "Eyes care Specialist-2",
        "Eyes care Specialist-3",
        "Eyes care Specialist-4",
      ],
      "Pediatrics Specialist": [
        "Pediatrics Specialist-1",
        "Pediatrics Specialist-2",
        "Pediatrics Specialist-3",
        "Pediatrics Specialist-4",
      ],
      "Hepatology Specialist": [
        "Hepatology Specialist-1",
        "Hepatology Specialist-2",
        "Hepatology Specialist-3",
        "Hepatology Specialist-4",
      ],
    };

    // Retrieve sub-options based on the selected main option
    return subOptionsData[mainOption] || [];
  };

  const nameChangeHandler = (event) => {
    const selectName = event.target.value;
    let onlyLetter = /^[a-zA-Z\s]*$/;
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      enterName: selectName,
    }));
    if (selectName.trim().length === 0) {
      setError((prevError) => ({
        ...prevError,
        nameError: "Please enter your name!",
      }));
    } else if (selectName.trim().length < 3) {
      setError((prevError) => ({
        ...prevError,
        nameError: "Please enter minimum 3 letters name!",
      }));
    } else if (!onlyLetter.test(selectName)) {
      setError((prevError) => ({
        ...prevError,
        nameError: "Please enter only letters!",
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        nameError: "",
      }));
    }
  };

  const emailChangeHandler = (event) => {
    const selectEmail = event.target.value;
    let emailValidation = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    // const user = window.localStorage.getItem("email");  //local storage email
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      enterEmail: selectEmail,
    }));
    if (selectEmail.trim().length === 0) {
      setError((prevError) => ({
        ...prevError,
        emailError: "Please enter your email address!",
      }));
    } else if (!emailValidation.test(selectEmail)) {
      setError((prevError) => ({
        ...prevError,
        emailError: "Please enter valid email address!",
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        emailError: "",
      }));
    }
  };

  const ageChangeHandler = (event) => {
    const selectAge = event.target.value;
    let age = /^[0-9\b]+$/;
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      enterAge: selectAge,
    }));
    if (selectAge.trim().length === 0) {
      setError((prevError) => ({
        ...prevError,
        ageError: "Please enter your age!",
      }));
    } else if (selectAge.trim() < 18) {
      setError((prevError) => ({
        ...prevError,
        ageError: "Please enter age 18 or more then 18 years old!",
      }));
    } else if (!age.test(selectAge)) {
      setError((prevError) => ({
        ...prevError,
        ageError: "Please enter only number!",
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        ageError: "",
      }));
    }
  };

  const idProofChangeHandler = (event) => {
    const selectIdType = event.target.value;
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      enterIdType: selectIdType,
    }));
    if (selectIdType.length === 0) {
      setError((prevError) => ({
        ...prevError,
        idProofError: "Please select your ID Proof!",
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        idProofError: "",
      }));
    }
  };

  const idCardNumberChangeHandler = (event) => {
    const selectIdCardNumber = event.target.value;
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      enterIdCardNumber: selectIdCardNumber,
    }));
    if (selectIdCardNumber.trim().length === 0) {
      setError((prevError) => ({
        ...prevError,
        idCardNumberError: "Please enter your ID Card Number!",
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        idCardNumberError: "",
      }));
    }
  };

  const doctorChangeHandler = (event) => {
    const selectDoctor = event.target.value;
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      enterDoctor: selectDoctor,
    }));
    if (selectDoctor.length === 0) {
      setError((prevError) => ({
        ...prevError,
        doctorError: "Please select your Speciality!",
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        doctorError: "",
      }));
    }
    // Fetch sub-options for the selected main option
    const fetchedSubOptions = getSubOptions(selectDoctor);
    setSubOptions(fetchedSubOptions);
  };

  const handleSubOptionChange = (event) => {
    const selectedSubOption = event.target.value;
    console.log(selectedSubOption);
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      enterSubDoctor: selectedSubOption,
    }));
    if (selectedSubOption.length === 0) {
      setError((prevError) => ({
        ...prevError,
        subDoctorError: "Please select your Specialist!",
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        subDoctorError: "",
      }));
    }
  };

  // select gender
  const maleClickHandler = () => {
    setMale(true);
    setFemale(false);
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      gender: "male",
    }));
  };

  const femaleClickHandler = () => {
    setMale(false);
    setFemale(true);
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      gender: "female",
    }));
  };

  const numberChangeHandler = (event) => {
    const selectNumber = event.target.value;
    let onlyNumber = /^[0-9\b]+$/;
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      enterNumber: selectNumber,
    }));
    if (selectNumber.trim().length === 0) {
      setError((prevError) => ({
        ...prevError,
        numberError: "Please enter your mobile number!",
      }));
    } else if (!onlyNumber.test(selectNumber)) {
      setError((prevError) => ({
        ...prevError,
        numberError: "Please enter only number!",
      }));
    } else if (
      selectNumber.trim().length < 10 ||
      selectNumber.trim().length > 10
    ) {
      setError((prevError) => ({
        ...prevError,
        numberError: "Please enter exactly 10 digits for the mobile number!",
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        numberError: "",
      }));
    }
  };

  const fileChangedHandler = (event) => {
    const selectFiles = event.target.files;
    setUploadedFiles(selectFiles);
    // console.log(selectFiles);
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      file: selectFiles,
    }));
    setError((prevError) => ({
      ...prevError,
      fileError: "",
    }));
  };

  
  // const mimeType = "audio/webm";
  const mimeType = "audio/wav";

  // for audio files events
  const audioRecordClickHandler = (event) => {
    event.preventDefault();
    setAudioRecord(true);
    setAudioFiles(false);
    // setError((prevError) => ({
    //   ...prevError,
    //   audioRecordError: "",
    // }));
  };

  const getMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        setPermission(true);
        setStream(streamData);
        setAudioRecord(false);
      } catch (err) {
        alert(err.message);
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  const startRecording = async () => {
    setAudioGif(true);
    setRecordingStatus("recording");
    setAudioFiles(false);
    setAudio(null);
    //create new Media recorder instance using the stream
    const media = new MediaRecorder(stream, { type: mimeType });
    //set the MediaRecorder instance to the mediaRecorder ref
    mediaRecorder.current = media;
    //invokes the start method to start the recording process
    mediaRecorder.current.start();
    let localAudioChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data);
    };
    setAudioChunks(localAudioChunks);
  };

  const stopRecording = () => {
    setRecordingStatus("recorded");
    setAudioGif(false);
    //stops the recording instance
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = () => {
      //creates a blob file from the audiochunks data
      const audioBlob = new Blob(audioChunks, { type: mimeType });
      //creates a playable URL from the blob file.
      const audioUrl = URL.createObjectURL(audioBlob);
      const audiofile = new File([audioBlob], "audiofile.wav", { type: "audio/wav" });
      console.log(audiofile);
      setAudio(audioUrl);
      setAudioChunks([]);
      setAppointmentInput((prevValue) => ({
        ...prevValue,
        audio: audiofile,
      }));
    };
  };

  const handleCreateNewAudio = () => {
    setRecordingStatus("inactive");
    setAudioGif(false);
    setAudio(null);
  };
  // for audio file upload button events
  const fileUploadClickHandler = (event) => {
    event.preventDefault();
    setAudioFiles(true);
    setAudioRecord(false);
    setPermission(false);
    setRecordingStatus("inactive");
    setAudio(null);
  };

  const audioFileChangeHandler = (event) => {
    const selectAudioFile = event.target.files[0];
    setUploadAudioFiles(selectAudioFile);
    console.log(selectAudioFile);
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      audioFile: selectAudioFile,
    }));
  };

  // for video events
  const videoMimeType = "video/mp4";

  const videoRecordOnClickHandler = (event) => {
    event.preventDefault();
    setVideoFiles(false);
    setVideoRecord(true);
    setVideoPermission(false);
    console.log(isRecordingComplete);
    // setIsRecordingComplete(false);
   
  };

  const getCameraPermission = async () => {
    
    setRecordedVideo(null);
    setVideoData(null);
    setIsRecordingComplete(false);
    setVideoPermission(true);
    setVideoStreamState(true);
    setVideoRecord(false);
    setIsVideoModelShow(true);
    setVideoRecordingStatus("inactive");
    // if ("MediaRecorder" in window) {
    //   try {
    //     const videoConstraints = {
    //       audio: false,
    //       video: true,
    //     };
    //     const audioConstraints = { audio: true };
    //     // create audio and video streams separately
    //     const audioStream = await navigator.mediaDevices.getUserMedia(
    //       audioConstraints
    //     );
    //     const videoStream = await navigator.mediaDevices.getUserMedia(
    //       videoConstraints
    //     );
    //     setVideoPermission(true);
    //     //combine both audio and video streams
    //     const combinedStream = new MediaStream([
    //       ...videoStream.getVideoTracks(),
    //       ...audioStream.getAudioTracks(),
    //     ]);
    //     setVideoStreamState(combinedStream);
    //     setVideoRecord(false);
    //     //set videostream to live feed player
    //     // Check if the ref is currently referencing a valid HTML element
    //     if (liveVideoFeed.current) {
    //       // Set the videoStream as the source object for the video element
    //       liveVideoFeed.current.srcObject = videoStream;
    //     }
    //     // liveVideoFeed.current.srcObject = videoStream;
    //   } catch (err) {
    //     alert(err.message);
    //   }
    // } else {
    //   alert("The MediaRecorder API is not supported in your browser.");
    // }
  };

  const startVideoRecording = async () => {
    
    setVideoRecordingStatus("recording");
    setVideoFiles(false);
    setVideoGif(true);
    setRecordedVideo(null);
    const media = new MediaRecorder(videoStreamState, { videoMimeType });
    mediaRecorder.current = media;
    mediaRecorder.current.start();
    let localVideoChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localVideoChunks.push(event.data);
    };
    setVideoChunks(localVideoChunks);
  };

  const stopVideoRecording = () => {
    setVideoPermission(false);
    setVideoRecordingStatus("recorded");
    setVideoGif(false);
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = () => {
      const videoBlob = new Blob(videoChunks, { type: videoMimeType });
      const videoUrl = URL.createObjectURL(videoBlob);
      setRecordedVideo(videoUrl);
      setVideoChunks([]);
      setAppointmentInput((prevValue) => ({
        ...prevValue,
        video: videoUrl,
      }));
    };
  };

  const handleRecordingComplete = async (event) => {
    // const elementRef = useRef(videoReff);
    const videoBlobURL = window.URL.createObjectURL(event);
    console.log(event);
    const videofile = new File([event], "videofile.mp4", { type: "video/mp4" })
    const changeView = videoReff.current;
    changeView.props = (data) => {
      console.log(data);
      // <DisconnectedView icon='null' />
    };
    console.log(videoReff.current.props);
    setIsRecordingComplete(true);
    setVideoPermission(false);
    setVideoRecordingStatus("recorded");
    setVideoData(videoBlobURL);
    console.log(videoData);
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      video: videofile,
    }));
  };

  const handleCreateNewVideo = () => {
    setIsRecordingComplete(false);
    setVideoData(null);
    setVideoRecordingStatus("inactive");
  };

  // click video upload button
  const videoFileUploadClickHandler = (event) => {
    event.preventDefault();
    setVideoFiles(true);
    setVideoRecord(false);
    setVideoPermission(false);
    setVideoRecordingStatus("inactive");
    setRecordedVideo(null);
    // setError((prevError) => ({
    //   ...prevError,
    //   videoRecordError: "",
    // }));
  };

  const videoFileChangeHandler = (event) => {
    const selectVideo = event.target.files[0];
    setUploadVideoFile(selectVideo);
    console.log(selectVideo);
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      videoFile: selectVideo,
    }));
  };

  // for message events
  const messageChangeHandler = (event) => {
    const messageValue = event.target.value;
    setAppointmentInput((prevValue) => ({
      ...prevValue,
      enterMessage: messageValue,
    }));
    if (appointmentInput.enterMessage.trim().length === 0) {
      setError((prevError) => ({
        ...prevError,
        messageError: "Please enter your message!",
      }));
    } else if (appointmentInput.enterMessage.trim().length <= 10) {
      setError((prevError) => ({
        ...prevError,
        messageError: "Please enter minimum 10 letters!",
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        messageError: "",
      }));
    }
  };

  // show model events
  const modalCloseHandler = (event) => {
    event.preventDefault();
    setIsFormSubmitted(false);
    setIsVideoModelShow(false);
  };

  // for submit button events
  const appointmentSubmitHandler = async (event) => {
    event.preventDefault();

    if (error.nameError) {
      setError((prevError) => ({
        ...prevError,
        nameError: "Please enter your full name!",
      }));
    } else if (appointmentInput.enterName.trim().length === 0) {
      setError((prevError) => ({
        ...prevError,
        nameError: "Name field is required!",
      }));
    } else if (error.emailError) {
      setError((prevError) => ({
        ...prevError,
        emailError: "Please enter your email address!",
      }));
    } else if (appointmentInput.enterEmail.length === 0) {
      setError((prevError) => ({
        ...prevError,
        emailError: "Email address field is required!",
      }));
    } else if (error.ageError) {
      setError((prevError) => ({
        ...prevError,
        ageError: "Please enter your age!",
      }));
    } else if (appointmentInput.enterAge.length === 0) {
      setError((prevError) => ({
        ...prevError,
        ageError: "Age field is required!",
      }));
    } else if (error.idProofError) {
      setError((prevError) => ({
        ...prevError,
        idProofError: "Please select your ID Proof!",
      }));
    } else if (appointmentInput.enterIdType.length === 0) {
      setError((prevError) => ({
        ...prevError,
        idProofError: "ID Proof field is required!",
      }));
    } else if (error.idCardNumberError) {
      setError((prevError) => ({
        ...prevError,
        idCardNumberError: "Please enter your ID Card number!",
      }));
    } else if (appointmentInput.enterIdCardNumber.trim().length === 0) {
      setError((prevError) => ({
        ...prevError,
        idCardNumberError: "ID Card number field is required!",
      }));
    } else if (error.doctorError) {
      setError((prevError) => ({
        ...prevError,
        doctorError: "Please select your Specialist!",
      }));
    } else if (appointmentInput.enterDoctor.length === 0) {
      setError((prevError) => ({
        ...prevError,
        doctorError: "Specialist field is required!",
      }));
    } else if (error.subDoctorError) {
      setError((prevError) => ({
        ...prevError,
        subDoctorError: "Please select your Specialist!",
      }));
    } else if (appointmentInput.enterSubDoctor.length === 0) {
      setError((prevError) => ({
        ...prevError,
        subDoctorError: "Specialist field is required!",
      }));
    } else if (error.numberError) { 
      setError((prevError) => ({
        ...prevError,
        numberError: "Please enter your mobile number!",
      }));
    } else if (appointmentInput.enterNumber.trim().length === 0) {
      setError((prevError) => ({
        ...prevError,
        numberError: "Phone number field is required!",
      }));
    } else if (fileInputRef.current.value === "") {
      setError((prevError) => ({
        ...prevError,
        fileError: "Please insert file!",
      }));
    } else if (error.messageError) {
      setError((prevError) => ({
        ...prevError,
        messageError: "Please enter your valid message!",
      }));
    } else if (appointmentInput.enterMessage.trim().length === 0) {
      setError((prevError) => ({
        ...prevError,
        messageError: "Message field is required!",
      }));
    } else {
      setAppointmentInput({
        enterName: "",
        enterEmail: email,
        enterAge: "",
        enterIdType: "",
        enterIdCardNumber: "",
        enterDoctor: "",
        enterNumber: "",
        enterMessage: "",
        gender: "male",
        file: [],
        audio: "",
        audioFile: [],
        video: "",
        videoFile: [],
      });
      // Use the uploadedFiles variable here
      console.log(uploadedFiles);
      console.log(uploadAudioFile);
      console.log(uploadVideoFile);
      setUploadedFiles([]);
      setAudioRecord(false);
      setPermission(false);
      setRecordingStatus("inactive");
      setAudio(null);
      setAudioFiles(false);

      setVideoRecord(false);
      setVideoPermission(false);
      setVideoRecordingStatus("inactive");
      setRecordedVideo(null);
      setVideoData(null);
      setVideoFiles(false);
      setIsRecordingComplete(false);

      // Reset file input value
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      // reset audio file input value
      if (audioFileRef.current) {
        audioFileRef.current.value = "";
      }
      // reset video file input value
      if (videoFileRef.current) {
        videoFileRef.current.value = "";
      }
    }

    try {
      // appointmentInput.append('file', uploadedFiles);
      console.log(appointmentInput);
      setIsLoading(true);
      await Baseurls
        .post("/appointments", appointmentInput, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
          },
          mode: "cors",
        })
        .then((res) => {

          console.log(res.data);
          setIsLoading(false);
          if (res.data.status === "true"){
            console.log("status: ");
            console.log(res.data.status);
            Swal.fire({  
              title: "Second Opinion Submitted!",  
              text: "Timing: 9AM to 5PM, and if within 48 hrs opinion not attended, then no charges will be taken.",
              icon: 'success'
            });
            setTimeout(() => {
              navigate('/');
          }, 500);
          } else{
            Swal.fire({  
              title: "Submission Failed!",  
              text: res.data.message,
              icon: 'error'
            });
          }
          //  setIsFormSubmitted(true);
        }) // Handle the response from backend here
        .catch((err) => {
          // setErrorMessage("Unable to fetch user list");
          setIsLoading(false);
        }); // Catch errors if any;
    } catch (e) {}
  };

  const titleHeading = "Guidelines to record video";
  const texOne = (
    <p style={{ fontSize: "12px" }}>
      <b>1:</b> To begin recording, just click on 'Start Video Recording'.
    </p>
  );
  const textTwo = (
    <p style={{ fontSize: "12px" }}>
      <b>2:</b> Next, click "Turn my camera ON" to allow access to your camera.
    </p>
  );
  const textThree = (
    <p style={{ fontSize: "12px" }}>
      <b>3:</b> Press the red-color record button to start recording your video.
    </p>
  );
  const textFour = (
    <p style={{ fontSize: "12px" }}>
      <b>4:</b> Your video can be of up to 2 minutes long.
    </p>
  );
  const textFive = (
    <p style={{ fontSize: "12px" }}>
      <b>5:</b> To stop recording, simply press the red-color stop record button.
    </p>
  );
  const textSix = (
    <p style={{ fontSize: "12px" }}>
      <b>6:</b> Once you stop the recording or reach the time limit, the video will be saved automatically.
    </p>
  );
  const textSeven = (
    <p style={{ fontSize: "12px" }}>
      <b>7:</b> If you want to keep a copy, click "Download Recording" to download the recorded video.
    </p>
  );

  return (
    <Fragment>
      <Form
        className={classes["appointment-one__form"]}
        onSubmit={appointmentSubmitHandler}
      >
        <Row className={classes["align-items-end"]}>
          <Col xs="12" lg="6">
            <label className={classes["appointment-one__label"]}>
              Full Name
            </label>
            <div className={classes["appointment-one__form-group"]}>
              <div className={classes["appointment-one__icon"]}>
                <FaUserAlt className={classes["material-icons"]} />
              </div>
              <input
                type="text"
                name="name"
                className={classes["appointment-one__field"]}
                placeholder="Full name"
                onChange={nameChangeHandler}
                value={appointmentInput.enterName}
              />
              {error.nameError && (
                <p className={classes.errorClasses}>{error.nameError}</p>
              )}
            </div>
          </Col>
          <Col xs="12" lg="6">
            <label className={classes["appointment-one__label"]}>
              Email Address
            </label>
            <div className={classes["appointment-one__form-group"]}>
              <div className={classes["appointment-one__icon"]}>
                <FaEnvelope className={classes["material-icons"]} />
              </div>
              <input
                type="text"
                name="email"
                className={classes["appointment-one__field"]}
                placeholder="Email Address"
                onChange={emailChangeHandler}
                value={appointmentInput.enterEmail}
                readOnly
              />
              {error.emailError && (
                <p className={classes.errorClasses}>{error.emailError}</p>
              )}
            </div>
          </Col>
          <Col xs="12" lg="6">
            <label className={classes["appointment-one__label"]}>Age</label>
            <div className={classes["appointment-one__form-group"]}>
              <div className={classes["appointment-one__icon"]}>
                <AiFillCalendar className={classes["material-icons"]} />
              </div>
              <input
                // type="date"
                type="text"
                name="age"
                minLength={2}
                maxLength={2}
                className={`${classes["appointment-one__field"]} ${classes["appointment-age"]}`}
                placeholder="Enter your age"
                onChange={ageChangeHandler}
                value={appointmentInput.enterAge}
              />
              {error.ageError && (
                <p className={classes.errorClasses}>{error.ageError}</p>
              )}
            </div>
          </Col>
          <Col xs="12" lg="6">
            <label className={classes["appointment-one__label"]}>Gender</label>
            <div className={classes["appointment-one__form-group"]}>
              <div className={classes["appointment-one__icon"]}>
                <FaUserAlt className={classes["material-icons"]} />
              </div>
              <div className={classes["appointment-one__switch-wrap"]}>
                <input
                  type="hidden"
                  name="gender"
                  value={male ? "male" : female ? "female" : ""}
                />
                <button
                  type="button"
                  onClick={maleClickHandler}
                  value={male}
                  className={`${male ? classes.active : ""} ${
                    classes["appointment-one__switch"]
                  }`}
                >
                  Male
                </button>
                <button
                  type="button"
                  onClick={femaleClickHandler}
                  value={female}
                  className={`${female ? classes.active : ""} ${
                    classes["appointment-one__switch"]
                  }`}
                >
                  Female
                </button>
              </div>
            </div>
          </Col>
          <Col xs="12" lg="6">
            <label className={classes["appointment-one__label"]}>
              ID Proof
            </label>
            <div className={`${classes["appointment-one__form-group"]}`}>
              <select
                className={classes.selectPickerItem}
                onChange={idProofChangeHandler}
                value={appointmentInput.enterIdType}
              >
                <option value="" defaultValue>
                  Select ID Proof
                </option>
                {ID_Proof.map((option) => {
                  return (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  );
                })}
              </select>
              {error.idProofError && (
                <p className={classes.errorClasses}>{error.idProofError}</p>
              )}
            </div>
          </Col>
          <Col xs="12" lg="6">
            <label className={classes["appointment-one__label"]}>
              ID Card Number
            </label>
            <div className={classes["appointment-one__form-group"]}>
              <div className={classes["appointment-one__icon"]}>
                <FaIdCard className={classes["material-icons"]} />
              </div>
              <input
                type="text"
                maxLength={12}
                name="id_number"
                className={`${classes["appointment-one__field"]}`}
                placeholder="Enter ID Card Number"
                onChange={idCardNumberChangeHandler}
                value={appointmentInput.enterIdCardNumber}
              />
              {error.idCardNumberError && (
                <p className={classes.errorClasses}>{error.idCardNumberError}</p>
              )}
            </div>
          </Col>
          <Col xs="12" lg="6">
            <label className={classes["appointment-one__label"]}>
              Specialities
            </label>
            <div className={`${classes["appointment-one__form-group"]}`}>
              <select
                className={classes.selectPickerItem}
                onChange={doctorChangeHandler}
                value={appointmentInput.enterDoctor}
              >
                <option value="" defaultValue>
                  Select Specialist
                </option>
                {Select_Doctor.map((option) => {
                  return (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  );
                })}
              </select>
              {error.doctorError && (
                <p className={classes.errorClasses}>{error.doctorError}</p>
              )}
            </div>
          </Col>
          <Col xs="12" lg="6">
            <label className={classes["appointment-one__label"]}>
              Select Doctor
            </label>
            <div className={classes["appointment-one__form-group"]}>
              <select
                onChange={handleSubOptionChange}
                className={classes.selectPickerItem}
              >
                <option value="" defaultValue>
                  Select Doctor
                </option>
                {subOptions.length > 0 &&
                  subOptions.map((subOption, index) => (
                    <option key={index} value={subOption}>
                      {subOption}
                    </option>
                  ))}
              </select>
              {error.subDoctorError && (
                <p className={classes.errorClasses}>{error.subDoctorError}</p>
              )}
            </div>
          </Col>
          <Col xs="12" lg="6">
            <label className={classes["appointment-one__label"]}>
              Phone Number
            </label>
            <div className={classes["appointment-one__form-group"]}>
              <div className={classes["appointment-one__icon"]}>
                <FaPhoneAlt className={classes["material-icons"]} />
              </div>
              <input
                type="text"
                maxLength={10}
                name="phone"
                className={`${classes["appointment-one__field"]}`}
                placeholder="Enter number"
                onChange={numberChangeHandler}
                value={appointmentInput.enterNumber}
              />
              {error.numberError && (
                <p className={classes.errorClasses}>{error.numberError}</p>
              )}
            </div>
          </Col>
          <Col xs="12" lg="6">
            <label className={classes["appointment-one__label"]}>File</label>
            <div
              className={`${classes["appointment-one__form-group"]} ${classes["appointment-one-form-file"]}`}
            >
              <div className={classes["appointment-one__icon"]}>
                <FaFileSignature className={classes["material-icons"]} />
              </div>
              <input
                type="file"
                name="files"
                className={`${classes["appointment-one__field2"]} ${classes["appointment-file"]} form-control`}
                onChange={fileChangedHandler}
                // value={uploadedFiles}
                multiple
                accept="application/pdf image/png, image/jpeg, image/jpg"
                ref={fileInputRef}
              />
              <label htmlFor="" className={`${classes["file-label"]}`}>
                Files allowed: .pdf, .jpg, .jpeg, .png
              </label>
              {error.fileError && (
                <p className={classes.errorClasses}>{error.fileError}</p>
              )}
            </div>
          </Col>
          {/* audio files */}
          <Col xs="12" lg="6" xl="6">
            <label className={classes["appointment-one__label"]}>
              Audio <span>&#40;Record or Upload&#41;</span>
            </label>
            <div className={classes["appointment-one__form-group"]}>
              <div className={classes["appointment-one__icon"]}>
                <AiFillAudio className={classes["material-icons"]} />
              </div>
              <div className={classes["appointment-one__switch-wrap"]}>
                <input type="hidden" name="audio" />
                <button
                  type="button"
                  onClick={audioRecordClickHandler}
                  className={`${audioRecord ? classes.active : ""} ${
                    classes["appointment-one__switch"]
                  }`}
                  value={audioRecord}
                >
                  Record
                </button>
                <button
                  type="button"
                  onClick={fileUploadClickHandler}
                  className={`${audioFiles ? classes.active : ""} ${
                    classes["appointment-one__switch"]
                  }`}
                  value={audioFiles}
                >
                  Upload
                </button>
              </div>
              {/* {error.audioRecordError && (
                <p
                  className={`${classes.errorClasses} ${classes["file-upload-error"]}`}
                >
                  {error.audioRecordError}
                </p>
              )} */}
              {audioRecord ? (
                <Button
                  className={classes["get-microphone"]}
                  onClick={getMicrophonePermission}
                >
                  Get Microphone
                </Button>
              ) : null}
              {permission && recordingStatus === "inactive" ? (
                <Button
                  className={classes["get-microphone"]}
                  onClick={startRecording}
                >
                  Start Recording
                </Button>
              ) : null}
              {recordingStatus === "recording" ? (
                <Button
                  className={classes["get-microphone"]}
                  onClick={stopRecording}
                >
                  Stop & Save Recording
                </Button>
              ) : null}
              {audioGif && (
                <Image
                  src={RecordAudioGif}
                  className={classes["record-gif"]}
                  alt="Gif"
                  fluid
                />
              )}
              {audio ? (
                <div className={classes["audio-container"]}>
                  <audio src={audio} controls></audio>
                  <Button
                    className={classes["create-video"]}
                    onClick={handleCreateNewAudio}
                  >
                    Take another audio
                  </Button>
                  &nbsp;
                  <Link download to={audio}>
                    Download Recording
                  </Link>
                </div>
              ) : null}
              {/* for audio files */}
              {audioFiles && (
                <div
                  className={`${classes["appointment-one__form-group"]} ${classes["appointment-one-form-file"]} ${classes["appointment-audio"]}`}
                >
                  <div className={classes["appointment-one__icon"]}>
                    <FaFileSignature className={classes["material-icons"]} />
                  </div>
                  <input
                    type="file"
                    name="audioFile"
                    className={`${classes["appointment-file"]} form-control ${classes["input-file"]}`}
                    accept="audio/*"
                    onChange={audioFileChangeHandler}
                    // value={uploadAudioFile}
                    ref={audioFileRef}
                  />
                  <label htmlFor="" className={`${classes["file-label"]}`}>
                    .opus, .flac, .wbem, .wbea, .wav, .ogg, .m4a, .mp3, .oga,
                    .mid, .aiff, .wma, .au, .acc
                  </label>
                </div>
              )}
            </div>
          </Col>
          {/* video files */}
          <Col xs="12" lg="6" xl="6">
            <label className={classes["appointment-one__label"]}>
              Video <span>&#40;Record or Upload&#41;</span>
            </label>
            <div className={classes["appointment-one__form-group"]}>
              <div className={classes["appointment-one__icon"]}>
                <AiFillVideoCamera className={classes["material-icons"]} />
              </div>
              <div className={classes["appointment-one__switch-wrap"]}>
                <input type="hidden" name="video" />
                <button
                  type="button"
                  className={`${videoRecord ? classes.active : ""} ${
                    classes["appointment-one__switch"]
                  }`}
                  onClick={videoRecordOnClickHandler}
                  value={videoRecord}
                >
                  Record
                </button>
                {isVideoModelShow && (
                  <Model name="videoRecordModel" onClose={modalCloseHandler}>
                    {{
                      title: titleHeading,
                      text: texOne,
                      textTwo: textTwo,
                      textThree: textThree,
                      textFour: textFour,
                      textFive: textFive,
                      textSix: textSix,
                      textSeven: textSeven,
                    }}
                  </Model>
                )}
                <button
                  type="button"
                  onClick={videoFileUploadClickHandler}
                  className={`${videoFiles ? classes.active : ""} ${
                    classes["appointment-one__switch"]
                  }`}
                  value={videoFiles}
                >
                  Upload
                </button>
              </div>
              {videoRecord ? (
                <Button
                  className={classes["get-microphone"]}
                  onClick={getCameraPermission}
                >
                  Start Video Recording
                </Button>
              ) : null}
              {!isRecordingComplete &&
              videoPermission &&
              videoRecordingStatus === "inactive" ? (
                <VideoRecorder
                  className={classes["video-player"]}
                  countdownTime={0}
                  timeLimit={120000}
                  icon={`<svg style="display: none;"></svg>`}
                  ref={videoReff}
                  onRecordingComplete={handleRecordingComplete}
                />
              ) : null}

              {isRecordingComplete && (
                <div className={classes["video-player"]}>
                  <video
                    style={{ width: "100%", height: "100%" }}
                    src={videoData}
                    controls
                  />
                  <div className={classes["video-download"]}>
                    <Button
                      className={classes["create-video"]}
                      onClick={handleCreateNewVideo}
                    >
                      Take another video
                    </Button>
                    &nbsp;
                    <Link download to={videoData}>
                      Download Recording
                    </Link>
                    {/* <a download href={videoData}>
                            Download Recording
                          </a> */}
                  </div>
                </div>
              )}
              {/* {videoPermission && videoRecordingStatus === "inactive" ? (
                <Button
                  className={classes["get-microphone"]}
                  onClick={startVideoRecording}
                >
                  Start Video Recording
                </Button>
              ) : null} */}
              {/* {videoGif && (
                <Image
                  src={RecordVideoGif}
                  className={classes["record-gif"]}
                  alt="Gif"
                  fluid
                />
              )} */}
              {/* {videoRecordingStatus === "recording" ? (
                <Button
                  className={classes["get-microphone"]}
                  onClick={stopVideoRecording}
                >
                  Start & Save Recording
                </Button>
              ) : null} */}
              {/* {recordedVideo ? (
                <div className={classes["video-player"]}>
                  <video src={recordedVideo} controls></video>
                  <Link download to={recordedVideo}>
                    Download Recording
                  </Link>
                </div>
              ) : null} */}
              {/* for video upload */}
              {videoFiles && (
                <div
                  className={`${classes["appointment-one__form-group"]} ${classes["appointment-one-form-file"]} ${classes["appointment-audio"]}`}
                >
                  <div className={classes["appointment-one__icon"]}>
                    <FaFileSignature className={classes["material-icons"]} />
                  </div>
                  <input
                    type="file"
                    name="videoFile"
                    className={`${classes["appointment-file"]} form-control ${classes["input-file"]}`}
                    accept="video/*"
                    onChange={videoFileChangeHandler}
                    // value={uploadVideoFile}
                    ref={videoFileRef}
                  />
                  <label htmlFor="" className={`${classes["file-label"]}`}>
                    .ogm, .wmv, .mpg, .webm, .ogv, .mv, .asx, .mpeg, .mp4, .m4v,
                    .avi
                  </label>
                </div>
              )}
            </div>
          </Col>
          <Col xs="12">
            <label className={classes["appointment-one__label"]}>Message</label>
            <div className={classes["appointment-one__form-group"]}>
              <textarea
                name="message"
                rows="6"
                className={`${classes["appointment-one__field"]} ${classes["appointment-form-textarea"]}`}
                placeholder="Enter your message..."
                onChange={messageChangeHandler}
                value={appointmentInput.enterMessage}
              ></textarea>
              {error.messageError && (
                <p className={classes.errorClasses}>{error.messageError}</p>
              )}
            </div>
          </Col>

          <Col xs="12" xl="3">
            <Button type="submit" className={classes["appointment-one__btn"]}>
              Submit Request
            </Button>
            {isLoading && <LoadingSpinner /> }
            {isFormSubmitted && (
              <Model name="appointmentModal" onClose={modalCloseHandler}>
                {{
                  title: "Success",
                  text: "Timing: 9AM to 5PM, and if within 48 hrs opinion not attended, then no charges will be taken",
                }}
              </Model>
            )}
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

export default AppointmentForm;
