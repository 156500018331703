import React, { Fragment } from "react";
import Banner from "../components/SameLayout/Banner/Banner";
import ExpertDoctor from "../components/ExpertsPage/ExpertDoctor/ExpertDoctor";

// images
import InnerBanner from "../assets/17.jpg";

const ExpertsPage = () => {
  return (
    <Fragment>
      <Banner
        bannerBackgroundImage={InnerBanner}
        pageTitle="Experts"
        parentPageTo="/"
        parentPageName="Home"
      />
      <ExpertDoctor />
    </Fragment>
  );
};

export default ExpertsPage;
