import React, { Fragment, useState, useEffect, useRef } from "react";
import InputText from "../../SameLayout/Ui/InputText/InputText";
import Button from "../../SameLayout/Ui/Button/Button";
import Checkbox from "../../SameLayout/Ui/Checkbox/checkbox";
import Model from "../../SameLayout/Ui/Modal/Model";
import { Baseurls } from "../../SameLayout/BaseUrl/Baseurls";
import { Link } from "react-router-dom";

// css
import { Col, Row } from "react-bootstrap";
import classes from "../SecondOpinion.module.css";


const RegistrationForm = () => {

  const [inputData, setInputData] = useState({
    enterName: "",
    enterEmail: "",
    enterNumber: "",
    enterPassword: "",
    enterConfirmPassword: "",
  });

  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const passwordRef = useRef(null);
  const declaractionRef = useRef(null);

  const [error, setError] = useState({
    nameError: "",
    emailError: "",
    numberError: "",
    passwordError: "",
    confirmPasswordError: "",
    declaractionError: " ",
  });
  const [isFormSubmitted, setIsFormSubmitted]=useState(false);

  // for term and conditions
  const [isChecked, setIsChecked] = useState(false);
  const [isRead, setIsRead] = useState(false);

  const nameChangeHandler = (event) => {
    const nameValue = event.target.value;
    
    let letterAndSpace = /^[a-zA-Z\s]*$/;
    setInputData({
      ...inputData,
      enterName: nameValue,
    });
    if (nameValue.trim().length === 0) {
      setError({
        ...error,
        nameError: "Please enter your name!",
      });
    } else if (!letterAndSpace.test(nameValue)) {
      setError({
        ...error,
        nameError: "Please enter only alphabet!",
      });
    } else if (nameValue.trim().length < 2) {
      setError({
        ...error,
        nameError: "Please enter minimum 2 alphabet!",
      });
    } else {
      setError({
        ...error,
        nameError: "",
      });
    }
  };

  const emailChangeHandler = (event) => {
    const emailValue = event.target.value;
    let emailValidation = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    setInputData({
      ...inputData,
      enterEmail: emailValue,
    });
    if (emailValue.trim().length === 0) {
      setError({
        ...error,
        emailError: "Please enter your email address!",
      });
    } else if (!emailValidation.test(emailValue)) {
      setError({
        ...error,
        emailError: "Please enter valid email!",
      });
    } else {
      setError({
        ...error,
        emailError: "",
      });
    }
  };

  const numberChangeHandler = (event) => {
    const selectNumber = event.target.value;
    let onlyNumber = /^[0-9\b]+$/;
    setInputData((prevValue) => ({
      ...prevValue,
      enterNumber: selectNumber,
    }));
    if (selectNumber.trim().length === 0) {
      setError((prevError) => ({
        ...prevError,
        numberError: "Please enter your mobile number!",
      }));
    } else if (!onlyNumber.test(selectNumber)) {
      setError((prevError) => ({
        ...prevError,
        numberError: "Please enter only number!",
      }));
    } else if (selectNumber.trim().length < 10 ) {
      setError((prevError) => ({
        ...prevError,
        numberError: "Please enter exactly 10 digits for the mobile number!",
      }));
    } else {
      setError((prevError) => ({
        ...prevError,
        numberError: "",
      }));
    }
  };

  const passwordChangeHandler = (event) => {
    const password = event.target.value;
    setInputData({
      ...inputData,
      enterPassword: password.trim(),
    });

    const passwordRegex = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    
    if (!password.match(passwordRegex)) {
      setError({
        ...error,
        passwordError: "Password should contains minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character!",
      });
    } else {
      setError({
        ...error,
        passwordError: "",
      });
    }
  };

  const confirmPasswordChangeHandler = (event) => {
    const confirmPassword = event.target.value;
    setInputData({
      ...inputData,
      enterConfirmPassword: confirmPassword.trim(),
    });

    if (confirmPassword.trim().length === 0) {
      setError({
        ...error,
        confirmPasswordError: "Please enter the same password again!",
      });
    } else if (confirmPassword.trim() !== inputData.enterPassword) {
      setError({
        ...error,
        confirmPasswordError:
          "Confirm Password mismatched!",
      });
    } else {
      setError({
        ...error,
        confirmPasswordError: "",
      });
    }
  };

  // for checkbox event
  const isCheckedChangeHandler = (event) => {
    const checked = event.target.value;
    console.log(checked);
    if(isChecked === true){
        setIsChecked(false);
        setError((prevError) => ({
            ...prevError,
            declaractionError: "Please click on checkbox to accept the terms and conditions!",
        }));
    }
    else {
        setIsChecked(true);
        setError((prevError) => ({
            ...prevError,
            declaractionError: " ",
        }));
    }
  }

  // terms Modal
  const termsChangeHandler = (event) => {
    console.log('terms');
    const checked = event.target.value;
    setIsRead(true);
    console.log(checked);
  }

  const titleHeading = "Terms and Conditions";
  const texOne = (
    <p style={{ fontSize: "12px" }}>
      The terms “We” / “Us” / “Our”/”Company” individually and collectively refer to SECOND OPINION and the terms “Visitor” ”User” refer to the users. 
    </p>
  );
  const textTwo = (
    <p style={{ fontSize: "12px" }}>
      This page states the Terms and Conditions under which you (Visitor) may visit this website (“Website”). Please read this page carefully. If you do not accept the Terms and Conditions stated here, we would request you to exit this site.
    </p>
  );
  const textThree = (
    <p style={{ fontSize: "12px" }}>
      <b>USE OF CONTENT: </b> All logos, brands, marks headings, labels, names, signatures, numerals, shapes or any combinations thereof, appearing in this site, except as otherwise noted, are properties either owned, or used under license, by the business and / or its associate entities who feature on this Website. The use of these properties or any other content on this site, except as provided in these terms and conditions or in the site content, is strictly prohibited.<br />
      You may not sell or modify the content of this Website  or reproduce, display, publicly perform, distribute, or otherwise use the materials in any way for any public or commercial purpose without the respective organization’s or entity’s written permission.

    </p>
  );
  const textFour = (
    <p style={{ fontSize: "12px" }}>
      <b>LIABILITY: </b> User agrees that Company shall not be responsible or liable to user, or anyone, for the statements or conduct of any third party of the service. In sum, in no event shall Company’s total liability to the User for all damages or/and losses or/and causes of action exceed the amount paid by the User to Company, if any, that is related to the cause of action.
    </p>
  );

  // show model events
  const modalCloseHandler = (event) => {
    event.preventDefault();
    setIsFormSubmitted(false);
    setIsRead(false);
  };

  // useEffect(() => {
  //   console.log(nameRef.current);
  // }, [nameRef]);

  //focus on mismatch fields
  const focusOnErrorField = (fieldName) => {
    
    if (fieldName === 'username') {
      console.log(nameRef); 
      console.log(nameRef.current); 
      // nameRef.current.focus();
    }else if (fieldName === 'email') {
      emailRef.current.focus();
    }else if (fieldName === 'phone') {
      phoneRef.current.focus();
    }else if (fieldName === 'password') {
      passwordRef.current.focus();
    }else if (fieldName === 'declaraction') {
      declaractionRef.current.focus();
    }
  };

  const RegistrationFormSubmitHandler = async (event) => {
    event.preventDefault();

    if (error.nameError) {
      focusOnErrorField('username');
      setError({
        ...error,
        nameError: "Please enter your valid name!",
      });
      // focusOnErrorField('name');
    } else if (inputData.enterName.length === 0) {
      
      setError({
        ...error,
        nameError: "Please enter your name!",
      });
      // focusOnErrorField('name');
    } else if (error.emailError) {
      setError({
        ...error,
        emailError: "Please enter your valid email address!",
      });
    } else if (inputData.enterEmail.length === 0) {
      setError({
        ...error,
        emailError: "Please enter your email address!",
      });
    } else if (error.numberError) {
      setError({
        ...error,
        numberError: "Please enter your valid phone number!",
      });
    } else if (inputData.enterNumber.length < 10) {
      setError({
        ...error,
        numberError: "Please enter your phone number!",
      });
    } else if (error.passwordError) {
      setError({
        ...error,
        passwordError: "Please enter valid password!",
      });
    } else if (inputData.enterPassword.length === 0) {
      setError({
        ...error,
        passwordError: "Please enter your password!",
      });
    } else if (error.confirmPasswordError) {
      setError({
        ...error,
        confirmPasswordError: "Please re-enter your password!",
      });
    } else if (inputData.enterConfirmPassword.length === 0) {
      setError({
        ...error,
        confirmPasswordError: "Please re-enter your password!",
      });
    } else {
      setInputData({
        enterName: "",
        enterEmail: "",
        enterNumber : "",
        enterPassword: "",
        enterConfirmPassword: "",
      });

      setIsChecked(false);
    }
      try {
        // appointmentInput.append('file', uploadedFiles);
        console.log(inputData);
        await Baseurls
          .post("/registration", inputData, {
            headers: {
              "Access-Control-Allow-Origin": '*',
              "Content-Type": "multipart/form-data",
            },
            mode: "cors",
          })
          .then((res) => {
            // console.log(res.data);
            if (res.data.status === "true") setIsFormSubmitted(true);
            if (res.data.status === "false") {
              setIsFormSubmitted(false);
              setError({
                ...error,
                emailError: "Your Email is already registered, please register with another email address!",
              });
            }
          }) // Handle the response from backend here
          .catch((err) => {
            console.log(err);
          }); // Catch errors if any;
      } catch (e) {
        console.log(e);
      }
  };

  useEffect(() => {
    focusOnErrorField('username');
  }, [error.nameError]);

  return (
    <Fragment>
      <form
        method="post"
        id="contact-form"
        onSubmit={RegistrationFormSubmitHandler}
        className={classes["contact-one__form"]}
      >
        <Row>
          <Col xs="12" md="12">
            <InputText
              type="text"
              name="username"
              onChange={nameChangeHandler}
              ref={nameRef}
              enterValue={inputData.enterName}
              enterError={error.nameError}
              placeholder="Your Name"
              autoFocus
              required
            />
          </Col>
          <Col xs="12" md="12">
            <InputText
              type="text"
              name="email"
              placeholder="Email address"
              onChange={emailChangeHandler}
              enterValue={inputData.enterEmail}
              enterError={error.emailError}
              ref={emailRef}
              required
            />
          </Col>
          <Col xs="12" lg="12">
              <InputText
                type="text"
                minLength={10}
                maxLength={10}
                name="phone"
                className={`${classes["appointment-one__field"]}`}
                placeholder="Enter phone number"
                onChange={numberChangeHandler}
                enterValue={inputData.enterNumber}
                enterError={error.numberError}
                ref={phoneRef}
                required
              />
          </Col>
          <Col xs="12" md="12">
            <InputText
              type="password"
              name="password"
              placeholder="Password"
              onChange={passwordChangeHandler}
              enterValue={inputData.enterPassword}
              enterError={error.passwordError}
              ref={passwordRef}
              required
            />
          </Col>
          <Col xs="12" md="12">
            <InputText
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password"
              onChange={confirmPasswordChangeHandler}
              enterValue={inputData.enterConfirmPassword}
              enterError={error.confirmPasswordError}
              required
            />
          </Col>
          <Col xs="12" md="12" >
              <div className={classes["d-inline"]}>
                <Checkbox 
                        type="checkbox"
                        name="regForm"
                        className=""
                        onChange={isCheckedChangeHandler}
                        value={isChecked}
                        ref={declaractionRef}
                    />&nbsp;
                    I have read the <button
                      type="button"
                      className={classes["link-button"]}
                      onClick={termsChangeHandler}
                    >Terms and Conditions</button>.
              </div>
              {error.declaractionError && (
                <p className={classes.errorClasses}>{error.declaractionError}</p>
              )}
              <br />
              { 
              isRead && (
                <Model name="termsModel" onClose={modalCloseHandler}>
                    {{
                      title: titleHeading,
                      text: texOne,
                      textTwo: textTwo,
                      textThree: textThree,
                      textFour: textFour,
                    }}
                  </Model>
                )
              }
          </Col>
          <Col
            xs="12"
            md="6"
            className={`${classes["form-group"]} ${classes["message-btn"]}`}
          >
            <Button type="submit" name="submit-form" >
              Register
            </Button><br /><br />
            Already registered? <Link to="/login" className={classes['signup']}>Click here </Link>to Login.
            { 
              isFormSubmitted && (
                <Model onClose={modalCloseHandler}>
                {{
                  title: "Success",
                  text: "You are successfully registered with Ek aur Opinion, please verify your email to login!",
                }}
                </Model>
              )
            }
          </Col>
        </Row>
      </form>
    </Fragment>
  );
};

export default RegistrationForm;
