import React, { Fragment } from "react";
import HeaderStrap from "../components/SameLayout/HeaderStrap/HeaderStrap";
import Register from "../components/SecondOpinionPage/Registration/registration";

// images
// import InnerBanner from "../assets/16.jpg";

import { Col, Row } from "react-bootstrap";
import classes from "../components/SecondOpinionPage/SecondOpinion.module.css";
import { Container } from "react-bootstrap";

const RegisterPage = () => {
  return (
    <Fragment>
        <HeaderStrap
        pageTitle="ekauropinion"
        parentPageTo="/"
        parentPageName="Home"
      />
        <section className={`${classes["registration-one"]}`}>
        <Container >
            <Row className={`${classes["registration-one_container"]}`}>
                <Col xs="12" lg="12">
                <h3 className={classes["register-one__title"]}>Get ek aur opinion</h3>
                <h5 ><p className={classes["sub-title"]}>Please register here to get ek aur opinion.<hr /></p></h5>
                </Col>
                <Register />
            </Row>
        </Container>
        </section>
    </Fragment>
  );
};

export default RegisterPage;
