import React, { Fragment } from "react";
import ContactForm from "../ContactForm/ContactForm";
import ContactMedium from "../ContactMedium/ContactMedium";


// css
import { Col, Container, Row } from "react-bootstrap";
import classes from './ContactDetails.module.css'

const ContactDetails = () => {
  return (
    <Fragment>
      <section className={classes["contact-one"]}>
        <Container fluid="lg">
          <Row>
            <Col xs="12" lg="7">
              <h3 className={classes["contact-one__title"]}>Get in Touch</h3>
              <ContactForm />
            </Col><Col xs="1" lg="1"></Col>
            <Col xs="12" lg='4'>
              <ContactMedium />
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default ContactDetails;
