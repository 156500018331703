import React, { Fragment } from "react";
import classes from "./checkbox.module.css";

const Checkbox = (props) => {
  return (
    <Fragment>
      <input
        type={props.type || "checkbox"}
        className={`${classes.checkboxClass} ${props.className}`} 
        name={props.name}
        onChange={props.onChange}
        checked={props.value}
      >
      </input>
    </Fragment>
  );
};

export default Checkbox;
