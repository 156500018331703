import React, { Fragment } from "react";
import { Link } from "react-router-dom";

// icons
import {BsInfoLg} from 'react-icons/bs'
import { FaFacebookF, FaInstagram, FaYoutube, FaTwitter } from "react-icons/fa";

// css
import classes from './ContactMedium.module.css'

const ContactMedium = () => {
  return (
    <Fragment>
      <div
          className={`${classes["contact-one__info"]} `}
      >
        <div className={classes["border-one"]}></div>
        <div className={classes["border-two"]}></div>

        {/* <h3 className={classes["contact-one__info__title"]}>Contact Detail</h3>
        <p className={classes["contact-one__info__text"]}>
          123 Second Polo, Karni Marg, Opposite Ship House, Paota, Jodhpur, Rajasthan - 342006
        </p> */}
        {/* <p className={classes["contact-one__info__text"]}>
          <span>Call us:</span> <br />
          <Link href="tel:8764821111">+91 876 482 1111</Link>
        </p> */}
        <p className={classes["contact-one__info__text"]}>
          <span>Mail Us:</span> <br />
          <Link href="mailto:connect@ekauropinion.com">connect@ekauropinion.com</Link>
        </p>
        <div className={classes["contact-one__info__social"]}>
          <Link 
            to="https://www.facebook.com/ekauropinion/"
            className={classes["item-link"]}
            target="_blank"
          >
            <FaFacebookF />
          </Link>
          <Link
            to="https://www.instagram.com/ekauropinion/"
            className={classes["item-link"]}
            target="_blank"
          >
            <FaInstagram />
          </Link>
          <Link to={e=>e.preventDefault()}>
            <FaYoutube />
          </Link>
          <Link to={e=>e.preventDefault()}>
            <FaTwitter />
          </Link>
        </div>
      </div>
    </Fragment>
  );
};

export default ContactMedium;
