import React, { Fragment } from "react";
import Banner from "../components/SameLayout/Banner/Banner";
import ContactDetails from "../components/ContactPage/ContactDetails/ContactDetails";
import Location from "../components/ContactPage/Location/Location";

// images
// import ContactBanner from "../assets/19.jpg";
import ContactBanner from "../assets/Banner_Contact-Us_page.jpg";

const ContactPage = () => {
  return (
    <Fragment>
      <Banner
        bannerBackgroundImage={ContactBanner}
        pageTitle="Contact Us"
        parentPageTo="/"
        parentPageName="Home"
      />
      <ContactDetails />
      {/* <Location /> */}
    </Fragment>
  );
};

export default ContactPage;
