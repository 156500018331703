import React, { Fragment } from "react";

// css
import classes from "./Textarea.module.css";

const Textarea = (props) => {
  const {
    message,
    placeholder,
    onChange,
    enterValue,
    enterError,
    maxLength,
    enterValueLength,
    rows,
  } = props;
  return (
    <Fragment>
      <div className={classes["form-group"]}>
        <textarea
          name={message}
          placeholder={placeholder}
          rows={rows}
          className={`form-control`}
          onChange={onChange}
          value={enterValue}
          maxLength={maxLength}
        ></textarea>
        {enterValueLength === 0 && (
          <p className={classes["letter-length"]}>0/500</p>
        )}
        {enterValueLength !== 0 && (
          <p
            className={`${classes["letter-length"]} ${classes["letter-length-start"]}`}
          >
            {enterValueLength}/500
          </p>
        )}
        {enterError && <p className={classes.error}>{enterError}</p>}
      </div>
    </Fragment>
  );
};

export default Textarea;
