import React, { Fragment } from 'react'

// css
import { Container } from 'react-bootstrap'
import classes from './Welcome.module.css'

const Welcome = () => {
  return (
    <Fragment>
        <section className={`${classes["sec-pad"]} text-center`}>
            <Container fluid="lg">
                <div className={`${classes["block-title__low-margin"]}`}>
                    <h2 className={classes["block-title__title"]}>Best Reliable Healthcare Solutions for Everyone</h2>
                    <p className={classes["block-title__text"]}>Medical diagnoses can be complex, and errors can happen to anyone. But here's the good news: getting EKaurOpinion can make a world of difference!</p>
                </div>
            </Container>
        </section>
    </Fragment>
  )
}

export default Welcome