import React, { Fragment } from 'react'

// css
import classes from './Error.module.css'

const Error = () => {
  return (
    <Fragment>
        <div className={classes["error-section"]}>
            <div className={`${classes['error-text']} text-center`}>
                <h2>PAGE NOT FOUND</h2>
            </div>
        </div>
    </Fragment>
  )
}

export default Error