import React, { Fragment } from "react";
import HeaderStrap from "../components/SameLayout/HeaderStrap/HeaderStrap";
import Register from "../components/Doctor/DetailForm/DoctorDetail";

// images
// import InnerBanner from "../assets/16.jpg";

import { Col, Row } from "react-bootstrap";
import classes from "../components/Doctor/DetailForm/DoctorDetail.module.css";
import { Container } from "react-bootstrap";

const DoctorPage = () => {
  return (
    <Fragment>
        <HeaderStrap
        pageTitle="ekauropinion"
        parentPageTo="/"
        parentPageName="Home"
      />
        <section className={`${classes["registration-one"]}`}>
        <Container fluid="lg" >
            <Row className={`${classes["registration-one_container"]}`}>
                <Col xs="12" lg="12">
                <h3 className={classes["register-one__title"]}>Register here as Doctor on ekauropinion</h3>
                {/* <h5 ><p className={classes["sub-title"]}>Please register here to get registered in second opinion.<hr /></p></h5> */}
                </Col>
                <Register />
            </Row>
        </Container>
        </section>
    </Fragment>
  );
};

export default DoctorPage;
