import React, { Fragment } from "react";
import OpinionNav from "../SameLayout/Navbar/OpinionNav";

const MainNavigation = () => {
  return (
    <Fragment>
      <OpinionNav />
    </Fragment>
  );
};

export default MainNavigation;
