import React, { Fragment, useState } from "react";
import InputText from "../../SameLayout/Ui/InputText/InputText";
import Textarea from "../../SameLayout/Ui/Textarea/Textarea";
import Button from "../../SameLayout/Ui/Button/Button";
import Model from "../../SameLayout/Ui/Modal/Model";
import { Baseurls } from "../../SameLayout/BaseUrl/Baseurls";
import Swal from 'sweetalert2';


// css
import { Col, Row } from "react-bootstrap";
import classes from "./ContactForm.module.css";


const ContactForm = () => {
  const [inputData, setInputData] = useState({
    enterName: "",
    enterEmail: "",
    enterSubject: "",
    enterPhone: "",
    enterMessage: "",
  });

  const [error, setError] = useState({
    nameError: "",
    emailError: "",
    subjectError: "",
    phoneError: "",
    messageError: "",
  });
  const [isFormSubmitted, setIsFormSubmitted]=useState(false)

  const nameChangeHandler = (event) => {
    const nameValue = event.target.value;
    let letterAndSpace = /^[a-zA-Z\s]*$/;
    setInputData({
      ...inputData,
      enterName: nameValue,
    });
    if (nameValue.trim().length === 0) {
      setError({
        ...error,
        nameError: "Please enter your name!",
      });
    } else if (!letterAndSpace.test(nameValue)) {
      setError({
        ...error,
        nameError: "Please enter only alphabet!",
      });
    } else if (nameValue.trim().length < 4) {
      setError({
        ...error,
        nameError: "Please enter minimum 4 alphabet!",
      });
    } else {
      setError({
        ...error,
        nameError: "",
      });
    }
  };

  const emailChangeHandler = (event) => {
    const emailValue = event.target.value;
    let emailValidation = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    setInputData({
      ...inputData,
      enterEmail: emailValue,
    });
    if (emailValue.trim().length === 0) {
      setError({
        ...error,
        emailError: "Please enter your email address!",
      });
    } else if (!emailValidation.test(emailValue)) {
      setError({
        ...error,
        emailError: "Please enter valid email!",
      });
    } else {
      setError({
        ...error,
        emailError: "",
      });
    }
  };

  const subjectChangeHandler = (event) => {
    const subjectValue = event.target.value;
    setInputData({
      ...inputData,
      enterSubject: subjectValue,
    });

    if (subjectValue.trim().length === 0) {
      setError({
        ...error,
        subjectError: "Please enter your subject!",
      });
    } else if (subjectValue.trim().length < 10) {
      setError({
        ...error,
        subjectError: "Please enter minimum 10 letters!",
      });
    } else {
      setError({
        ...error,
        subjectError: "",
      });
    }
  };

  const phoneChangeHandler = (event) => {
    const phoneValue = event.target.value;
    let phoneValidation = /^[0-9\b]+$/;
    setInputData({
      ...inputData,
      enterPhone: phoneValue,
    });

    if (phoneValue.trim().length === 0) {
      setError({
        ...error,
        phoneError: "Please enter your phone number!",
      });
    } else if (!phoneValidation.test(phoneValue)) {
      setError({
        ...error,
        phoneError: "Please enter only number!",
      });
    } else if (phoneValue.trim().length !== 10) {
      setError({
        ...error,
        phoneError:
          "Please enter 10 digits mobile number without country code!",
      });
    } else {
      setError({
        ...error,
        phoneError: "",
      });
    }
  };

  const messageChangeHandler = (event) => {
    const messageValue = event.target.value;
    if (messageValue.length <= 500) {
      setInputData({
        ...inputData,
        enterMessage: messageValue,
      });
    }
    if (messageValue.trim().length <= 10) {
      setError({
        ...error,
        messageError: "Please enter your message minimum 10 letters!",
      });
    } else {
      setError({
        ...error,
        messageError: "",
      });
    }
  };

  // show model events
  const modalCloseHandler = (event) => {
    event.preventDefault();
    setIsFormSubmitted(false);
  };

  const contactFormSubmitHandler = async (event) => {
    event.preventDefault();

    if (error.nameError) {
      setError({
        ...error,
        nameError: "Please enter your valid name!",
      });
    } else if (inputData.enterName.length === 0) {
      setError({
        ...error,
        nameError: "Please enter your name!",
      });
    } else if (error.emailError) {
      setError({
        ...error,
        emailError: "Please enter your valid email address!",
      });
    } else if (inputData.enterEmail.length === 0) {
      setError({
        ...error,
        emailError: "Please enter your email address!",
      });
    } else if (error.subjectError) {
      setError({
        ...error,
        subjectError: "Please enter valid subject!",
      });
    } else if (inputData.enterSubject.length === 0) {
      setError({
        ...error,
        subjectError: "Please enter your subject!",
      });
    } else if (error.phoneError) {
      setError({
        ...error,
        phoneError: "Please enter valid phone number!",
      });
    } else if (inputData.enterPhone.length === 0) {
      setError({
        ...error,
        phoneError: "Please enter your phone number!",
      });
    } else if (error.messageError) {
      setError({
        ...error,
        messageError: "Please enter valid message!",
      });
    } else if (inputData.enterMessage.length <= 0) {
      setError({
        ...error,
        messageError: "Please enter your message minimum 10 letters!",
      });
    } else {
      setInputData({
        enterName: "",
        enterEmail: "",
        enterSubject: "",
        enterPhone: "",
        enterMessage: "",
      });

      try {
        // inputData.append('file', uploadedFiles);
        console.log(inputData);
        // setIsLoading(true);
        await Baseurls
          .post("/contact", inputData, {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
            },
            mode: "cors",
          })
          .then((res) => {
  
            console.log(res.data);
            const message = res.data.message;
            // setIsLoading(false);
            if (res.data.status === "true"){
              Swal.fire({  
                title: "Query Submitted!",  
                text: message,
                icon: 'success'
              });

            } else{
              
            }
            //  setIsFormSubmitted(true);
          }) // Handle the response from backend here
          .catch((err) => {
            // setErrorMessage("Unable to fetch user list");
            // setIsLoading(false);
          }); // Catch errors if any;
      } catch (e) {}

      // setIsFormSubmitted(true)
    }
  };

  return (
    <Fragment>
      <form
        method="post"
        id="contact-form"
        onSubmit={contactFormSubmitHandler}
        className={classes["contact-one__form"]}
      >
        <Row>
          <Col xs="12" md="6">
            <InputText
              type="text"
              name="username"
              onChange={nameChangeHandler}
              enterValue={inputData.enterName}
              enterError={error.nameError}
              placeholder="Your Name"
            />
          </Col>
          <Col xs="12" md="6">
            <InputText
              type="text"
              name="email"
              placeholder="Email address"
              onChange={emailChangeHandler}
              enterValue={inputData.enterEmail}
              enterError={error.emailError}
            />
          </Col>
          <Col xs="12" md="6">
            <InputText
              type="text"
              name="subject"
              placeholder="Subject"
              onChange={subjectChangeHandler}
              enterValue={inputData.enterSubject}
              enterError={error.subjectError}
            />
          </Col>
          <Col xs="12" md="6">
            <InputText
              type="text"
              maxLength={10}
              name="phone"
              placeholder="Phone"
              onChange={phoneChangeHandler}
              enterValue={inputData.enterPhone}
              enterError={error.phoneError}
            />
          </Col>
          <Col xs="12">
            <Textarea
              name="message"
              placeholder="Message"
              onChange={messageChangeHandler}
              enterValue={inputData.enterMessage}
              enterError={error.messageError}
              maxLength="500"
              rows="8"
              enterValueLength={inputData.enterMessage.length}
            />
          </Col>
          <Col
            xs="12"
            className={`${classes["form-group"]} ${classes["message-btn"]}`}
          >
            <Button type="submit" name="submit-form">
              submit now
            </Button>
            {
              isFormSubmitted && (
                <Model onClose={modalCloseHandler}>
                {{
                  title: "Success",
                  text: "Your form is successfully submitted, we will connect you soon!",
                }}
                </Model>
              )
            }
          </Col>
        </Row>
      </form>
    </Fragment>
  );
};

export default ContactForm;
