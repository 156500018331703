import React, { Fragment } from "react";
import Banner from "../components/SameLayout/Banner/Banner";
import SecondOpinion from "../components/SecondOpinionPage/secondOpinion";

// images
// import InnerBanner from "../assets/16.jpg";
import InnerBanner from "../assets/Banner_Second-Opinion_Page.jpg";

const SecondOpinionPage = () => {
  return (
    <Fragment>
      <Banner
        bannerBackgroundImage={InnerBanner}
        pageTitle="ekauropinion"
        parentPageTo="/"
        parentPageName="Home"
      />
      <SecondOpinion />
    </Fragment>
  );
};

export default SecondOpinionPage;
