import React, { useState, useEffect, Fragment } from "react";

// icons
import { FaFacebookF, FaInstagram, FaYoutube, FaTwitter } from "react-icons/fa";

// css
import classes from "./Footer.module.css";
import { Col, Container, Row, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FooterData } from "./FooterData";

//logo
import logo from "../../../assets/EAO_logo.png";
import footerImage from "../../../assets/footer-banner.jpg";

const Footer = () => {

    // Set an initial count value from localStorage or default to 0
    const initialCount = localStorage.getItem('count');
  
  return (
    <Fragment>
      <footer className={classes["footer-one"]}>
        <div className={classes["footer-one__middle"]}>
          <Container fluid="lg">
            <div className={classes["inner-container"]}>
              <Row className={`align-items-start`}>
                <Col xs="12" md="6" lg="4">
                  <div className={`${classes["footer-one_widget"]}`}>
                    <Link
                      to="/"
                      className={classes["footer-one__text"]}
                    >
                      <img src={logo} alt="EkAurOpinion" className={classes['footer-logo']}></img>
                      
                      {/* <h3>ekauropinion</h3> */}
                    </Link>
                    <div className={`${classes["footer-brand-text"]} px-2`}>
                      <p >
                        Ekauropinion is a common, sometimes necessary step in helping you find the best care. It helps you make sure you get the best care.
                      </p>
                    </div>
                    
                  </div>
                </Col>
                <Col xs="12" md="6" lg="3">
                  <div className={`${classes["footer-one_widget"]}`}>
                    <h3 className={classes["footer-one__widget-title"]}>
                      Quick Links
                    </h3>
                    <ul className={classes["footer-one__contact-list"]}>
                      <li
                        className={classes["footer-one__contact-list-item"]}
                      >
                        <Link to="/aboutus">
                         &gt; Who We Are
                        </Link>
                      </li>
                      <li
                        className={classes["footer-one__contact-list-item"]}
                      >
                        <Link to="/getopinion">
                        &gt; Ekauropinion
                        </Link>
                      </li>
                      <li
                        className={classes["footer-one__contact-list-item"]}
                      >
                        <Link to="/expert">
                        &gt; Experts
                        </Link>
                      </li>
                      <li
                        className={classes["footer-one__contact-list-item"]}
                      >
                        <Link to="/blog">
                        &gt; Blogs
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col xs="12" md="6" lg="3">
                  <div className={`${classes["footer-one_widget"]}`}>
                    <h3 className={classes["footer-one__widget-title"]}>
                      Follow Us
                    </h3>
                    <div className={classes["follow-us-text"]}>
                      
                      <ul className={`${classes["follow-us"]} list-inline`}>
                        <li className={`list-inline-item`}>
                          <Link
                            to="https://www.facebook.com/ekauropinion/"
                            className={classes["item-link"]}
                            target="_blank"
                          >
                            <FaFacebookF />
                          </Link>
                        </li>

                        <li className={`list-inline-item`}>
                          <Link
                            to="https://www.instagram.com/ekauropinion/"
                            className={classes["item-link"]}
                            target="_blank"
                          >
                            <FaInstagram />
                          </Link>
                        </li>

                        <li className={`list-inline-item`}>
                          <Link
                            to={(e) => e.preventDefault()}
                            className={classes["item-link"]}
                          >
                            <FaYoutube />
                          </Link>
                        </li>

                        <li className={`list-inline-item`}>
                          <Link
                            to={(e) => e.preventDefault()}
                            className={classes["item-link"]}
                          >
                            <FaTwitter />
                          </Link>
                        </li>
                      </ul>
                    </div>
                    
                  </div>
                </Col>
                <Col xs="12" md="6" lg="2">
                  <div className={`${classes["footer-one_widget"]}`}>
                    <h3 className={classes["footer-one__widget-title"]}>
                      Email Us
                    </h3>
                    <ul className={classes["footer-one__contact-list"]}>
                      <li
                        className={classes["footer-one__contact-list-item"]}
                      >
                        <Link to="mailto:connect@ekauropinion.com">
                          connect@ekauropinion.com
                        </Link>
                      </li>
                      <li className={classes["footer-one__contact-list-item"]}>
                      <h3 className={classes["footer-one__widget-title"]}>
                        Total Visitors: {initialCount}
                      </h3>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
