import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Banner from "../components/SameLayout/Banner/Banner";
import BlogDetailText from "../components/BlogDetailPage/BlogDetailText/BlogDetailText";
import { Link } from "react-router-dom";

// icons
import { BsClock } from "react-icons/bs";

// images
import InnerBanner from "../assets/23.jpg";
// import BlogDetailFirstImage from "../assets/blog/cervical_cancer_awareness.png";
// import BlogDetailSecondImage from "../assets/13.jpg";

// css
import { Col, Container, Row } from "react-bootstrap";
import { Image } from "react-bootstrap";
import classes from "./BlogDetailsPage.module.css";

// blogs
// import { blogs } from "../BlogDetailPage/BlogItems";
import { blogs} from "../components/BlogDetailPage/BlogItems";

import {Baseurls} from "../components/SameLayout/BaseUrl/Baseurls";

const BlogDetailPage = () => {

  const { blogSlug } = useParams();
  const [data, setData] = useState(null);
  const [date, setDate] = useState(null);
  
  const [blogPosts, setBlogPosts] = useState([]);

  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  useEffect(() => {

        // Fetch data from the API
        const fetchData = async () => {
          try {
              Baseurls
              .get("/getBlog")
              .then((response) => {
                console.log(response.data);

                const foundData = response.data.find(item => item.slug === blogSlug);
                console.log(foundData);
                
                if (foundData) {
                  const publishDate = foundData.added_at;

                  const originalDate = new Date(publishDate);

                  // Format the date as "01-02-2024"
                  const formattedDate = originalDate.toLocaleDateString(undefined, options); // 'en-GB' represents the British English date format
                  setDate(formattedDate);
                  setData(foundData);
                  
                } else {
                  // Handle case where no matching data is found
                  console.error(`Data with id ${blogSlug} not found.`);
                }

                setBlogPosts(response.data);
                console.log(blogPosts);
              })
          } catch (error) {
            console.error('Error fetching blog posts:', error);
          }
        };
    
        fetchData();

    // Find the data item with the matching id
    console.log(blogSlug);
  
    // const foundData = blogPosts.find(item => item.slug === blogSlug);
    // console.log(foundData);

    
    // if (foundData) {
    //   setData(foundData);
    // } else {
    //   // Handle case where no matching data is found
    //   console.error(`Data with id ${blogSlug} not found.`);
    // }

  }, [blogSlug]);


  return (
    <Fragment>
      <Banner
        bannerBackgroundImage={InnerBanner}
        pageTitle="Blog Detail"
        parentPageTo="/"
        parentPageName="Home"
      />
      <section className={classes["blog-details"]}>
        <Container fluid="lg">
          <Row>
            <Col xs="12">
              {data ? (
              // <BlogDetailText
              //   blogTitle={data.title}
              //   addDate={data.added_at}
              //   blogTextOne={data.text_one}
              //   blogDetailFirstImage={data.image}
              //   blogDetailFirstImageAlt={data.slug}
              //   blogTextTwo={data.text_two}
              // />
              <Fragment>
                <div className={classes["blog-details__content"]}>
                  <h3 className={classes["blog-one__title"]}>{data.title}</h3>

                  <div className={classes["blog-details__meta"]}>
                    <Link to="">
                      <BsClock /> {date}
                    </Link>
                  </div>
                  {/* <p className={classes["blog-one__text_heading"]}>{blogTextOneHeading}</p> */}
                  {/* <p className={classes["blog-one__text"]}>{text_one}</p> */}
                  <p className={classes["blog-one__text"]} dangerouslySetInnerHTML={{ __html: data.text_one }} ></p>
                            
                  <Image
                    src={data.image}
                    className={classes["blog-details__image"]}
                    alt={data.slug}
                    fluid
                  />
                  {/* <p className={classes["blog-one__text"]}>{text_two}</p> */}
                  <p className={classes["blog-one__text"]} dangerouslySetInnerHTML={{ __html: data.text_two }} ></p>
                            
                  
                </div>
                <div className={classes["blog-details__post-nav"]}>
                  <Link to="javascript:void(0)" className={classes["blog-details__post-nav-btn"]}>
                    Prev
                  </Link>
                  <Link to="javascript:void(0)" className={classes["blog-details__post-nav-btn"]}>
                    Next
                  </Link>
                </div>
              </Fragment>

              ) : (
                <p>Loading data...</p>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default BlogDetailPage;
