export const SecondMedicalOpinionData = [
  {
    id: "t1",
    tabEventKey: "tab-1",
    tabTitle: "Mission",
    tabParaOne:
      "Empowering patients with clarity and confidence. Every voice deserves a second listen.",
    tabParaTwo:
      " ",
    tabButtonText: "Get Ek aur Opinion",
  },
  {
    id: "t2",
    tabEventKey: "tab-2",
    tabTitle: "Vision",
    tabParaOne:
      "Redefining healthcare pathways by making expert second opinions accessible to all, ensuring every medical decision is informed, inderstood, and trusted.",
    tabParaTwo: " ",
    tabButtonText: "Get Ek aur Opinion",
  },
  // {
  //   id: "t3",
  //   tabEventKey: "tab-3",
  //   tabTitle: "Success",
  //   tabParaOne:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incid- idunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
  //   tabParaTwo:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //   tabButtonText: "Get Ek aur Opinion",
  // },
  // {
  //   id: "t4",
  //   tabEventKey: "tab-4",
  //   tabTitle: "History",
  //   tabParaOne:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incid- idunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
  //   tabParaTwo:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //   tabButtonText: "Get Ek aur Opinion",
  // },
];
