import React, { Fragment, useState } from "react";
import { format, parseISO } from 'date-fns';
import Button from "../../SameLayout/Ui/Button/Button";
import { Link } from 'react-router-dom';

// icons
import { BsLink, BsClock } from "react-icons/bs";

// css
import { Row, Col, Image } from "react-bootstrap";
import classes from "./BlogItemBox.module.css";

const BlogItemBox = (props) => {



  return (
    <Fragment>
      {props.blog_item_data.map((data) => {
        return (
          <Col xs="12" md="12" lg="12" key={data.blog_id}>
            <Row className={classes["blog-one__single"]}>
              {/* <div className={classes["blog-one__single"]}> */}
              <div className={classes["blog-one__content"]}>
                <Col xs="9" md="9" lg="9">
                    <div className={classes["blog-one__top"]}>
                      <h3
                        className={classes["blog-one__title"]}
                        onClick={data.blogOnclick}
                      >
                        {data.title}
                      </h3>
                      <p
                        className={classes["blog-one__date"]}
                        onClick={data.blogOnclick}
                      >
                        <BsClock />  {format(parseISO(data.added_at), "do MMMM yyyy")}
                      </p>
                      {/* <p className={classes["blog-one__text"]}>{data.blogText}</p> */}
                      {/* <DangerousHTML html={data.text_one} /> */}
                      <p className={classes["blog-one__text"]} dangerouslySetInnerHTML={{ __html: data.highlights }} ></p>
                    </div>
                    
                    <div className={classes["blog-one__bottom"]}>
                      <Link to={`/blog/${data.slug}`}>
                        <Button type="button">
                          Read More
                        </Button>
                      </Link>
                    </div>
                </Col>
                <Col xs="3" md="3" lg="3" className={classes["blog_image"]}>
                  <div className={classes["blog-one__image"]}>
                    <Image fluid src={data.image} alt={data.title} />
                    <div className={classes["blog-one__image-hover"]}>
                    </div>
                  </div>
                </Col>
              </div>
            </Row>
          </Col>
        );
      })}
    </Fragment>
  );
};

export default BlogItemBox;
