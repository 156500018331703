import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Testimonials.module.css";
import { Avatar } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { TestimonialCarouselItem } from "./TestimonialCarouselItem";

const PreviousBtn = (props) => {
  console.log(props);
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowBackIos style={{ color: "gray", fontSize: "45px", marginLeft: -25}} />
    </div>
  );
};
const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowForwardIos style={{ color: "gray", fontSize: "45px", marginRight: -25}} />
    </div>
  );
};
const Testimonial = () => {
  return (
    <div
      className="testimonial"
      style={{ background: '#f7f8fb', display: "flex", justifyContent: "center", paddingTop: 50, paddingBottom: 50 }}
    >
      <div style={{ width: "50%", textAlign: "center" }}>
        <h1 style={{ color: "#304262", fontSize: 36, fontWeight: 700, fontFamily: 'Poppins', marginBottom: 30 }}>Testimonial</h1>
        <Slider prevArrow={<PreviousBtn />} nextArrow={<NextBtn />} dots>
                {TestimonialCarouselItem.map((data, key) => {
                     return (
                        <div
                          className="testimonial_text"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            textAlign: "center",
                            color: "gray",
                          }}
                        >
                          <Avatar
                            imgProps={{ style: { borderRadius: "50%" } }}
                            src={data.carouselImage}
                            style={{
                              width: 120,
                              height: 120,
                              border: "1px solid lightgray",
                              padding: 7,
                              marginBottom: 30,
                              margin: 'auto',
                            }}
                          />
                          <p>
                          {data.paragraphText}
                          </p>
                          <p style={{ fontStyle: "italic", marginTop: 25 }}>
                            <span style={{ fontWeight: 500, color: "blue" }}>{data.Title}, </span>
                            {data.designation}
                          </p>
                        </div>
                      );
                })}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonial;