import React, { Fragment } from "react";
import Banner from "../components/SameLayout/Banner/Banner";
import FAQ from "../components/FAQ/FAQ";

// images
import InnerBanner from "../assets/11.jpg";

const FAQPage = () => {
  return (
    <Fragment>
      <Banner
        bannerBackgroundImage={InnerBanner}
        pageTitle="Frequently Asked Question"
        parentPageTo="/"
        parentPageName="Home"
      />
      <FAQ />
    </Fragment>
  );
};

export default FAQPage;
