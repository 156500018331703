import React, { Fragment } from "react";
import HeaderStrap from "../components/SameLayout/HeaderStrap/HeaderStrap";
import Login from "../components/SecondOpinionPage/Login/login";

// images
// import InnerBanner from "../assets/16.jpg";

import { Col, Row } from "react-bootstrap";
import classes from "../components/SecondOpinionPage/SecondOpinion.module.css";
import { Container } from "react-bootstrap";

const LoginPage = () => {
  return (
    <Fragment>
        <HeaderStrap
        pageTitle="ekauropinion"
        parentPageTo="/"
        parentPageName="Home"
      />
        <section className={`${classes["registration-one"]} ${classes["login"]}`}>
        <Container fluid="lg" >
            <Row className={`${classes["registration-one_container"]}`}>
                <Col xs="12" lg="12">
                <h3 className={classes["register-one__title"]}>Get ek aur opinion</h3>
                <h5 ><p className={classes["sub-title"]}>Please login here to get ek aur opinion.<hr /></p></h5>
                </Col>
                <Login />
            </Row>
        </Container>
        </section>
    </Fragment>
  );
};

export default LoginPage;
