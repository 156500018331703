// import images
import SlideTwo from "../../../assets/9.jpg";
import SlideOne from "../../../assets/2Banner.jpg";
import SlideThree from "../../../assets/3Banner.jpg";
// import SlideTwo from "../../../assets/m2.jpg";
// import SlideOne from "../../../assets/m1.jpg";
// import SlideThree from "../../../assets/m3.jpg";


export const MobileHealthCarouselItem=[
    {
        id: "c1",
        carouselImage: SlideOne,
        headingText: "Get ekauropinion Online",
        paragraphText: "Get the Healthcare Answers You Deserve.",
        imageAlt: "Slider One"
    },
    {
        id: "c2",
        carouselImage: SlideTwo,
        headingText: "Get ekauropinion Online",
        paragraphText: "ekauropinion provides the clarity and confidence you need.",
        imageAlt: "Slider Two"
    },
    {
        id: "c3",
        carouselImage: SlideThree,
        headingText: "Get ekauropinion Online",
        paragraphText: "Get the Healthcare Answers You Deserve. ekauropinion provides the clarity and confidence you need.",
        imageAlt: "Slider Three"
    },
]