import React, { Fragment } from "react";
import { Link } from "react-router-dom";

// css
import { Container } from "react-bootstrap";
import classes from './Banner.module.css'


const Banner = (props) => {
  const {bannerBackgroundImage,pageTitle,parentPageName,parentPageTo}=props;

  return (
    <Fragment>
      <section className={classes["inner-banner"]} style={{backgroundImage: `url(${bannerBackgroundImage})`}}>
        <Container>
          <h2 className={classes["inner-banner__title"]}>{pageTitle}</h2>
          <ul className={classes["thm-breadcrumb"]}>
            <li>
              <Link to={parentPageTo}>{parentPageName}</Link>
            </li>
            <li>
              <span className={classes.sep}>/</span>
            </li>
            <li>
              <span className={classes["page-title"]}>{pageTitle}</span>
            </li>
          </ul>
        </Container>
      </section>
    </Fragment>
  );
};

export default Banner;
